import React, { useState, useEffect, useCallback } from 'react';
import { Accordion } from 'react-bootstrap';
import RangeSlider from '../../components/range/RangeSlider';

import { useFormFields } from '../../libs/hooks';
import userService from '../../services/user';
import { secureFetch } from '../../libs/utils';

function Settings() {

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [fields, handleFieldChange, setAll] = useFormFields({
    unsignedDocumentKeepDays: 0,
    signedDocumentKeepDays: 0,
    allowSignatureWithPen: true,
    allowSignatureWithMouse: true,
    allowSignatureWithTouch: true,
    allowEarlyFinalization: false,
  });

  const fetchData = useCallback(async () => {
    await secureFetch(
      () => userService.getConfiguration(),
      (response) => setAll(response.configuration),
      setErrorMessage,
      'Nem sikerült lekérdezni a beállításokat'
    );
  }, [setAll]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const save = async () => {
    setSuccessMessage('');
    await secureFetch(
      () => userService.updateConfiguration(fields),
      () => setSuccessMessage('Elmentettük a módosításokat'),
      setErrorMessage,
      'Nem sikerült elmenteni a konfiguráció módosításait'
    );
  };

  return (
    <Accordion>
      <div className="dashboard__inner">
        <div className="dashboard__inner--row">
          <h2>Beállítások</h2>
        </div>
      </div>

      {errorMessage && (
        <div className="dashboard__inner--row">
          <span className="error">{errorMessage}</span>
        </div>
      )}
      {successMessage && (
        <div className="dashboard__inner--row">
          <span className="success">{successMessage}</span>
        </div>
      )}

      <div className="dashboard__inner--row">
        <h3 className="mb-4">Ingyenes Word Plugin letöltése</h3>
        <a className="btn" href="/files/SignoWiseWordAddin.exe">
          Letöltöm
        </a>
      </div>
      <div className="dashboard__inner--row">
        <h3 className="mb-4">
          <b>
            Dokumentumok automatikus törlése az aláírómappákból
          </b>
        </h3>
        <h3>Aláírandó dokumentumok</h3>
        <RangeSlider
          value={fields.unsignedDocumentKeepDays}
          minValue={1}
          maxValue={30}
          onChange={(value) => handleFieldChange({
            target: {
              id: 'unsignedDocumentKeepDays',
              value
            }
          })}
        />
        <h3>Aláírt dokumentumok</h3>
        <RangeSlider
          value={fields.signedDocumentKeepDays}
          minValue={1}
          maxValue={30}
          onChange={(value) => handleFieldChange({
            target: {
              id: 'signedDocumentKeepDays',
              value
            }
          })}
        />
      </div>
      <div className="dashboard__inner--row">
        <h3>Aláírási mód engedélyezése</h3>
        <div className="license">
          <div className="license__inner">
            <h4>Tollal</h4>
            <div>
              <input
                className="apple-switch"
                type="checkbox"
                id="allowSignatureWithPen"
                onChange={handleFieldChange}
                checked={fields.allowSignatureWithPen}/>
            </div>
          </div>
          <div className="license__inner">
            <h4>Ujjal</h4>
            <div>
              <input
                className="apple-switch"
                type="checkbox"
                id="allowSignatureWithTouch"
                onChange={handleFieldChange}
                checked={fields.allowSignatureWithTouch}/>
            </div>
          </div>
          <div className="license__inner">
            <h4>Egérrel</h4>
            <div>
              <input
                className="apple-switch"
                type="checkbox"
                id="allowSignatureWithMouse"
                onChange={handleFieldChange}
                checked={fields.allowSignatureWithMouse}/>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard__inner--row">
        <div className="license">
          <div className="license__inner">
            <h4>
              Dokumentum véglegesítésének engedélyezése
            </h4>
            <div>
              <input
                className="apple-switch"
                type="checkbox"
                id="allowEarlyFinalization"
                onChange={handleFieldChange}
                checked={fields.allowEarlyFinalization}/>
            </div>
            <p>
              Ha be van kapcsolva, akkor az aláírófelületen a
              “Véglegesítés” gombbal akkor is véglegesíthető egy dokumentum,
              ha még van olyan aláíró, aki nem írta alá.
              A dokumentum az Aláírandó mappában marad,
              a hiányzó aláírásokat egy későbbi időpontban is lehet pótolni.
            </p>
          </div>
        </div>
      </div>
      <div className="dashboard__inner--row">
        <button className="btn" onClick={save}>
          Mentés
        </button>
      </div>
    </Accordion>
  );
}

export default Settings;
