import React from 'react';
import { Accordion } from 'react-bootstrap';

import SubscriptionSummary from './SubscriptionSummary';
import UsageStats from './UsageStats';

function Subscriptions() {
  return (
    <Accordion>
      <div className="dashboard__inner">
        <div className="dashboard__inner--row">
          <h2>Előfizetés</h2>
        </div>
        <SubscriptionSummary/>
        <UsageStats/>
      </div>
    </Accordion>
  );
}

export default Subscriptions;
