import { Tab, Tabs } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';

import Settings from './sections/Settings';
import SignedDocuments from './sections/SignedDocuments';
import UnsignedDocuments from './sections/UnsignedDocuments';
import Purchase from './sections/Purchase';
import Orders from './sections/Orders';
import Profile from './sections/Profile';
import Subscriptions from './sections/Subscriptions';
import Helper from './sections/Helper';
import authService from '../services/auth';

function Dashboard() {
  const currentUser = authService.getCurrentUser();

  const { tab } = useParams();
  const history = useHistory();

  const activeKey = tab ??
    (currentUser.disableHelp ? 'unsigned' : 'helper');

  return (
    <main className="dashboard">
      <section>
        <Tabs
          activeKey={activeKey}
          onSelect={(key) => history.push(`/dashboard/${key}`)}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <Tab title="Aláírómappák">
          </Tab>
          <Tab eventKey="unsigned" title="Aláírandók">
            <UnsignedDocuments/>
          </Tab>
          <Tab eventKey="signed" title="Aláírt dokumentumok">
            <SignedDocuments/>
          </Tab>
          <Tab title="Menü">
          </Tab>
          <Tab eventKey="subscription" title="Előfizetés">
            <Subscriptions/>
          </Tab>
          <Tab eventKey="purchase" title="Vásárlás">
            <Purchase/>
            <Orders/>
          </Tab>
          <Tab eventKey="settings" title="Beállítások">
            <Settings/>
          </Tab>
          <Tab eventKey="user" title="Felhasználói adatok">
            <Profile/>
          </Tab>
          <Tab eventKey="helper" title="Segítség">
            <Helper/>
          </Tab>
        </Tabs>
      </section>
    </main>
  );
}

export default Dashboard;
