import { Link as RouterLink } from 'react-router-dom';
import { Link } from 'react-scroll';
import { useEffect } from 'react';
import logo from '../../images/logo.svg';
import jQuery from 'jquery';

const Header = (props) => {
  useEffect(() => {
    const toggler = jQuery('.navbar-toggler');
    const navbar = jQuery('.navbar-collapse');
    jQuery('nav a').on('click', () => {
      if (toggler.css('display') !== 'none') {
         if (navbar.css('display') !== 'none') {
           toggler.trigger('click');
         }
      }
    });
  }, []);

  return (
    <header>
      <div className="container">
        <nav class="navbar navbar-expand-lg navbar-light">
          <a href="/">
            <img src={logo} className="logo" alt="logo" />
          </a>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto align-items-center">
              <Link to="prices"
                smooth={false}
                offset={-100}
                className="scroll"
                isDynamic={true}
              >
                Árak
              </Link>
              <Link to="faq"
                smooth={false}
                offset={-100}
                className="scroll"
                isDynamic={true}
              >
                Tudástár
              </Link>
              <RouterLink to="/login">
                <button className="btn">Bejelentkezés</button>
              </RouterLink>
              <RouterLink to="/register">
                <button className="btn">Kipróbálom díjmentesen</button>
              </RouterLink>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
