import axios from 'axios';
import { BASE_URL, getAxiosConfig } from './common.js'

const API_URL = `${BASE_URL}/subscription/`

const getOrders = () => {
  return axios.get(
      API_URL + 'getOrders',
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const getProducts = () => {
  return axios.get(
      API_URL + 'getProducts',
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const getUserStatus = () => {
  return axios.get(
      API_URL + 'getUserStatus',
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const getUsageData = () => {
  return axios.get(
      API_URL + 'getUsageData',
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const getDetailedUsageData = (dateStr) => {
  const date = parseInt(dateStr);
  return axios.post(
      API_URL + 'getDetailedUsageData',
      { date },
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const downloadInvoice = (orderId) => {
  return axios.post(
      API_URL + 'downloadInvoice',
      { orderId },
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const createOrder = (order) => {
  return axios.post(
      API_URL + 'createOrder',
      order,
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const validateOrder = (order) => {
  return axios.post(
      API_URL + 'validateOrder',
      order,
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const validateBillingAddress = (address) => {
  return axios.post(
      API_URL + 'validateBillingAddress',
      address,
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const requestTrialSignatures = () => {
  return axios.post(
      API_URL + 'requestTrialSignatures',
      {},
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const services = {
  getOrders,
  getProducts,
  downloadInvoice,
  createOrder,
  getUserStatus,
  getUsageData,
  getDetailedUsageData,
  validateOrder,
  validateBillingAddress,
  requestTrialSignatures
};

export default services;
