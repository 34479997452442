import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import {Accordion, Tab, Tabs} from 'react-bootstrap';
import arrowBtn from '../../images/arrow-btn.svg';
import QuestionModal from '../../components/modal/QuestionModal';

const Faq = ({
  activeTab, setActiveTab, activeAccordion, setActiveAccordion
}) => {

  const [ showQuestionModal, setShowQuestionModal ] = useState(false);

  return (
    <section className="faq" id="faq">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="title">
              <h3>
                <Fade left>
                  Tudástár
                </Fade>
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="faq__inner">
              <Tabs defaultActiveKey="how-to" className="faq__inner--header" activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)} unmountOnExit={false} mountOnEnter={false}>
                <Tab eventKey="how-to" title="Kinek és miért éri meg?">
                  <Accordion activeKey={activeAccordion} onSelect={e => setActiveAccordion(e)}>
                     <div className="faq__inner--line" id="hogyan-tudok-akar">
                        <Accordion.Item eventKey="0"><Accordion.Header>
                            Hogyan tudok akár 1 000 Ft-ot spórolni dokumentumonként, avagy miért éri meg a SignoWise?
                        </Accordion.Header></Accordion.Item>
                        <Accordion.Collapse eventKey="0" className="answer">
                          <div>
                            <p>A digitalizáció a papírmentességgel kezdődik. A papírmentesség pedig az elektronikus aláírással.</p>
                            <p>Az előnyök hosszan sorolhatóak:</p>
                            <p>Munkaerőköltség: ha minden nyomtatással csak 2 percet tölt egy 300 000 Ft bérköltségű alkalmazott, az kb. 70 Ft-ot jelent dokumentumonként.</p>
                            <p>Ha a papírról az adatokat kézzel kell rögzíteni, az további 3 perc, azaz kb. 100 Ft egy egyszerű dokumentum esetén is.</p>
                            <p>Papír és nyomtatási költség: ez kb. 5 Ft / oldal</p>
                            <p>Várakozási idő - értékesítés során a legfontosabb szempont: a dokumentumok elektronikusan azonnal átadhatók a távoli backoffice-ba, a folyamatok drasztikusan felgyorsulnak.</p>
                            <p>Hibák csökkentése: többé nem fog az aláírt papíron és a rendszerben szereplő adat eltérni, nem lehet azokat papíron módosítani. Az elektronikus dokumentum további automatikus ellenőrzéseket is lehetővé tesz.</p>
                            <p>Automatikus feldolgozás megalapozása: a teljesen elektronikus dokumentumkezelés az automatikus feldolgozást és folyamatokat is lehetővé teszi, megalapozza.</p>
                            <p>Ügyfélélmény és környezetvédelem: tapasztalataink szerint az aláírók kifejezetten értékelik a környezetvédelmi aspektust. A papírgyártás a fa-, és vízfelhasználásban és a CO2-kibocsátásban is vezető iparág.</p>
                          </div>
                        </Accordion.Collapse>

                    </div>
                   <div className="faq__inner--line" id="kiknek-eri-meg-leginkabb">
                      <Accordion.Item eventKey="1"><Accordion.Header>
                        Kiknek éri meg leginkább a SignoWise?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="1" className="answer">
                        <div>
                          <p>Annak, aki sok aláíró partnerrel sok szerződést szeretne aláírni. Minél magasabb a dokumentumok száma, annál nagyobb az üzleti előny.</p>
                          <p>Kevés, de gyakori aláírók esetében a minősített e-aláírás is megfontolandó.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="aliro-partnereimnek-miert-jo-a-signowise">
                      <Accordion.Item eventKey="aliro-partnereimnek-miert-jo-a-signowise"><Accordion.Header>
                        Aláíró partnereimnek miért jó a SignoWise?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="aliro-partnereimnek-miert-jo-a-signowise" className="answer">
                        <div>
                          <p>Az aláírási folyamat egyszerű, intuitív és gyorsan lezajlik.</p>
                          <p>Az aláírónak csak írni kell tudnia - nincs szükség a minősített e-aláírás miatti extra utánjárásra, költségre, hardverre vagy szoftverre.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                  </Accordion>
                </Tab>
                <Tab eventKey="law" title="Jogi megfelelés">
                  <Accordion activeKey={activeAccordion} onSelect={e => setActiveAccordion(e)}>
                    <h5>Jogmentes jogi összefoglaló</h5>
                    <div className="faq__inner--line" id="miert-bizzak-a-signowise-jogi-megfelelossegeben">
                      <Accordion.Item eventKey="0"><Accordion.Header>
                        Miért bízzak a SignoWise jogi megfelelősségében?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="0" className="answer">
                        <div>
                          <p>Az OTP Bank és a Magyar Közjegyzői Kamara is felhasználója a megoldásunknak, amit az MNB is auditált. Már több 10 millió dokumentumot állítottunk elő, több mint 2 millió magyar aláíróval. A SignoWise első banki bevezetését egyéves jogi előkészítés előzte meg, elismert e-aláírás szakértők bevonásával.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="mit-kell-tennem-a-jogilag-megfelelo-hasznalathoz">
                      <Accordion.Item eventKey="1"><Accordion.Header>
                        Mit kell tennem a jogilag megfelelő használathoz?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="1" className="answer">
                        <div>
                          <p>Két dolgot: </p>
                          <ol>
                          <li>Elvárt szerződés forma ellenőrzése: a SignoWise Ptk. szerinti írásbeli szerződést állít elő. Az előírt szerződésforma az adott szerződést szabályozó törvényben található. A Ptk. szerinti írásbeliség a leggyakoribb forma, ami pl. a banki, egészségügyi és munkajogi szerződések túlnyomó részéhez megfelelő.</li>
                          <li>
                            <p>Aláíró azonosítása: a felhasználó feladata az aláíró azonosítása. Az azonosítás a SignoWise használatától függetlenül is kötelezően elvégzendő. Az azonosítást az adott szerződést szabályozó törvényben előírt módon kell megtenni (pl. személyi igazolvány + lakcímkártya, vagy TAJ kártya). </p>
                            <p>Tehát csak megfelelően ellenőrzött ügyféladatokkal feltöltött dokumentumot írassunk alá az aláíróval, ugyanúgy, ahogyan a SignoWise nélkül is tennénk papíron. A papíralapú Ptk. szerinti írásbeli és a SignoWise szerződések jogi háttere azonos.</p>
                          </li>
                          </ol>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="miert-nekem-kell-azonositani-az-alairo-partneremet">
                      <Accordion.Item eventKey="2"><Accordion.Header>
                        Miért nekem kell azonosítani az aláíró partneremet, páciensemet, üzletfelemet?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="2" className="answer">
                        <div>
                          <p>Az azonosítás minden tranzakció, avagy szerződés kötelező része az aláírás módjától függetlenül - ezt jogszabály írja elő.</p>
                          <p>Továbbá az azonosítás követelményei iparág-specifikusak, így a harmadik fél által elvégzett azonosítás sokszor nem is megfelelő.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="mi-van-ha-valaki-az-alairasa-helyett-kiscicat-rajzol-az-alairo-mezobe">
                      <Accordion.Item eventKey="3"><Accordion.Header>
                        Mi van, ha valaki az aláírása helyett kiscicát rajzol az aláíró mezőbe? :)
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="3" className="answer">
                        <div>
                          <p>Ugyanaz, mint amit a hagyományos papír alapú aláírásnál: megkérjük hogy írjon alá szabályosan, ahogyan papíron is tenné. :)</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <h5>Ptk. szerinti írásbeliség</h5>
                    <div className="faq__inner--line" id="mi-a-signowise-e-alairas-jogi-hattere">
                      <Accordion.Item eventKey="4"><Accordion.Header>
                        Mi a SignoWise e-aláírás jogi háttere?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="4" className="answer">
                        <div>
                          <p>A SignoWise Ptk. szerinti írásbeli szerződést állít elő. A Ptk. szerinti írásbeli szerződések, nyilatkozatok általánosan használtak a mindennapi üzleti életben, mint pl. munkaszerződések, egészségügyi nyilatkozatok, banki és pénzügyi szerződések. A megoldás már évek óta, több millió felhasználóval működik zökkenőmentesen az OTP Bankban és más ügyfeleknél is.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="mikor-van-szukseg-ptk-szerinti-irasbeli-szerzodes-kotesere?">
                      <Accordion.Item eventKey="5"><Accordion.Header>
                        Mikor van szükség Ptk. szerinti írásbeli szerződés kötésére?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="5" className="answer">
                        <div>
                          <p>Minden szerződéstípus (pl. munkaszerződés, banki szerződések) rendelkezik egy jogilag elvárt formátummal, amely a szabályozó törvényben szerepel. Ez minden szerződéstípusra ellenőrizhető, a leggyakoribb elvárás a Ptk. szerinti írásbeli szerződés.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="mi-kell-a-ptk-szerinti-irasbeliseg-teljesulesehez">
                      <Accordion.Item eventKey="6"><Accordion.Header>
                        Mi kell a Ptk. szerinti írásbeliség teljesüléséhez?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="6" className="answer">
                        <div>
                          <p>Ptk. 6:7. § szerint [Írásbeli alakhoz kötött jognyilatkozat]</p>
                          <p>Írásba foglaltnak kell tekinteni a jognyilatkozatot (...), ha annak közlésére a jognyilatkozatban foglalt tartalom változatlan visszaidézésére, a nyilatkozattevő személyének és a nyilatkozat megtétele időpontjának azonosítására alkalmas formában kerül sor.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="hogyan-teljesulnek-a-ptk-szerinti-irasbeliseg-feltetelei-a-signowise-zal">
                      <Accordion.Item eventKey="7"><Accordion.Header>
                        Hogyan teljesülnek a Ptk. szerinti írásbeliség feltételei a SignoWise-zal?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="7" className="answer">
                        <ol>
                          <li>
                          <p>(1) tartalom változatlan visszaidézése: a SignoWise a pontos tartalmat kriptográfiailag védi, így a pontos tartalom bizonyíthatóan eredeti. Akár egy karakter változása egy Biblia méretű szövegben is azonnal látható a kriptográfiai védelmi megoldásunkkal.</p>
                          </li>
                          <li>
                            <p>(2) nyilatkozattevő személyének azonosítására alkalmas: az aláíró azonosítása elkerülhetetlenül a szerződést elkészítő cég feladata, az aláíró azonosítása (személyi igazolvány, lakcímkártya elkérése) nélkül semmilyen szerződés nem köthető. A SignoWise az aláírandó dokumentumot már ezen adatokkal együtt kapja.</p>
                          <p>Az aláírás a megjelenített írásképen túl a teljes biometrikus aláírási adattartalmat elmenti és titkosítva csatolja a dokumentumhoz. Ez alapján az aláírás akár írásszakértői elemzésre is alkalmas, így a hagyományos kézi aláíráshoz hasonlóan működik.</p>
                          </li>
                          <li>
                          <p>(3) nyilatkozat megtétele időpontjának azonosítására alkalmas: a SignoWise az aláírás során minősített időbélyeget csatol elválaszthatatlanul minden dokumentumhoz.</p>
                          </li>
                        </ol>
                      </Accordion.Collapse>
                    </div>
                    <h5>Hol használható a SignoWise megoldás?</h5>
                    <div className="faq__inner--line" id="kell-e-talalkoznom-az-ugyfellel">
                      <Accordion.Item eventKey="8"><Accordion.Header>
                        Kell-e találkoznom az ügyféllel?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="8" className="answer">
                        <div>
                          <p></p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="mit-jelent-az-hogy-a-signowise-tetszoleges-helyszinrol-hasznalhato-megoldas">
                      <Accordion.Item eventKey="9"><Accordion.Header>
                        Mit jelent az, hogy a SignoWise tetszőleges helyszínről használható megoldás?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="9" className="answer">
                        <div>
                          <p>A SignoWise szinte bármilyen kézi aláírás rögzítésére és webes tartalom megjelenítésére alkalmas eszközzel használható.</p>
                          <p>Ha a mobilitás maximalizálása a cél, akkor érdemes egy tablet alapú, pl. iPad megoldáson elgondolkodni. Az iPad képes sok web alapú vállalati rendszert futtatni, amiben rögzíthetők az ügyfél- és ügyletadatok, továbbá a SignoWise e-aláírásra is alkalmas. Ezáltal egy elegáns, egyeszközös megoldást tud a vállalat működtetni, akár külső helyszíneken is, ami pl. értékesítő ügynökök esetén különösen előnyös.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="hasznalhato-e-tavolrol-is-a-megoldas?">
                      <Accordion.Item eventKey="10"><Accordion.Header>
                        Használható-e távolról is a megoldás?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="10" className="answer">
                        <div>
                          <p>Technikailag ennek semmilyen akadálya nincs, ugyanakkor a jogi szempontokat érdemes figyelembe venni. Amennyiben az azonosítás megoldott (pl. egy korábbi találkozó során), illetve a szerződés nem magas kockázatú, a SignoWise használata megfelelő lehet úgy is, hogy a cég ügyintézője nincs személyesen jelen.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <h5>Más e-aláírás megoldások megfelősége</h5>
                    <div className="faq__inner--line" id="miben-kulonbozik-a-signowise-es-az-elterjedt-tavolrol-is-hasznalhato-e-alairas-szolgaltatasok">
                      <Accordion.Item eventKey="11"><Accordion.Header>
                        Miben különbözik a SignoWise és az elterjedt, távolról is használhato e-aláírás szolgáltatások?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="11" className="answer">
                        <div>
                          <p>A népszerű távoli e-aláíró megoldások, mint a DocuSign, általában nem Ptk. írásbeli szerződést állítanak elő, hiszen nem a magyar vagy akár az EU-s joghoz tervezettek. Ezért csak olyan esetekben alkalmazhatók, amikor egyébként sem kötelező Ptk. szerinti írásbeli szerződést kötni.</p>
                          <p>Természetesen a drágább megoldások képesek megfelelő jogi erejű szerződést előállítani, ezek költsége viszont kifejezetten magas, nagy volumenű felhasználásra nem gazdaságos, ezért ezen opciók használata nem is jellemző.</p>
                          <p>Néhány megoldás eleve kizárja a szerződéses problémák kezelését, mint pl. valaki vitatja az aláírást, akkor a szolgáltató automatikusan szerződést bont. Ezáltal a szolgáltatás lényege kérdőjeleződik meg, így nagyobb kockázatú szerződésekhez ezen megoldások használata sem javasolt.</p>
                          <p>A SignoWise ezzel szemben Ptk. szerinti írásbeli szerződést ad, ahol a részletes biometrikus aláírás akár bírósági eljárásban, írásszakértővel is vizsgálható.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="mi-a-minositett-e-alairas">
                      <Accordion.Item eventKey="12"><Accordion.Header>
                        Mi a minősített e-aláírás?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="12" className="answer">
                        <div>
                          <p>A minősített e-aláírás az eIDAS EU rendelet által bevezetett EU szintű e-aláírás megoldási keret. A működésének alapelve, hogy egy minősített bizalmi szolgáltató az aláíró azonosítása után egy elektronikus aláíró pecsétet bocsát ki, ami ún. minősített e-aláírásra alkalmas. Ezt az egyes országok implementálták, helyi szabályozási megoldásokkal.</p>
                          <p>Az eIDAS elviekben egy közös jogi és technikai keretet teremt az e-aláírások számára, különösen a minősített e-aláírások terén. A gyakorlati implementáció azonban a helyi sajátosságok miatt nem tökéletes, így a minősített e-aláírások használata számos EU-s országban, így Magyarországon sem széleskörűen elterjedt.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="miert-kevesbe-alkalmas-a-minositett-e-alairas-tomeges-ugyfeloldali-alairasra">
                      <Accordion.Item eventKey="13"><Accordion.Header>
                        Miért kevésbé alkalmas a minősített e-aláírás tömeges ügyféloldali aláírásra?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="13" className="answer">
                        <div>
                          <p>A minősített e-aláírás egy költségesebb, némi szakértelmet és külön szoftvert és/vagy eszközt igénylő megoldás, ami pont ezért csak a cégvezetők, ügyvédek és más gyakori e-aláírók közt terjedt el. A szélesebb tömegű elterjedése nagyon lassú, annak ellenére hogy évek óta elérhető, még mindig csak a népesség kb. 1 százaléka használ minősített e-aláírást.</p>
                          <p>A fenti hátrányok miatt sok, ügyfeleivel rendszeresen szerződő, nagyobb ügyfélbázisú cég fordul más megoldás, pl. a SigoWise irányába.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="miert-jo-a-signowise-annak-akinek-van-mar-minositett-e-alairasa-is">
                      <Accordion.Item eventKey="14"><Accordion.Header>
                        Miért jó a SignoWise annak, akinek van már minősített e-aláírása is?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="14" className="answer">
                        <div>
                          <p>Még ha van is minősített aláírása aláíróidnak, azok egymástól technikailag különbözhetnek, különféle szoftveres és hardveres megoldásokat használnak. Ezen eltérések miatt különféle technikai megoldások szükségesek a kölcsönös elfogadáshoz. Ezt még a legnagyobb cégek sem tudják biztosítani, és persze a kisebb cégek számára sem praktikus 1-2 típusnál többet elfogadni.</p>
                          <p>A SignoWise használatához csak írni kell tudni.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <h5>Bírósági eljárás</h5>
                    <div className="faq__inner--line" id="mi-tortenik-ha-valaki-birosagon-megtamad-egy-alairt-dokumentumot?">
                      <Accordion.Item eventKey="15"><Accordion.Header>
                        Mi történik, ha valaki bíróságon megtámad egy aláírt dokumentumot?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="15" className="answer">
                        <div>
                          <p>A folyamat majdnem ugyanaz, mint egy hagyományos papírdokumentum esetén. A dokumentum az egyéb bizonyítékokkal együtt a bíróság számára átadásra kerül. A dokumentumban lévő részletes biometrikusaláírás-adat a külön titkosító kulccsal, naplózva kititkosításra, majd írásszakértőhöz kerül, aki a hagyományos, papíralapú ügyekhez hasonlóan megvizsgálja őket, majd véleményt mond.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="mi-tortenik-ha-az-ugyfelem-ujjal-firkalta-ala-a-szerzodest">
                      <Accordion.Item eventKey="16"><Accordion.Header>
                        Mi történik, ha az ügyfelem ujjal firkálta alá a szerződést?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="16" className="answer">
                        <div>
                          <p>A SignoWise-ban szabályozható, hogy engedélyezzük-e az ujjal való aláírást. Amennyiben egy szerződéses vita kockázata és/vagy értéke alacsony, mint pl. kisértékű kézbesítés, akkor ez a megoldás is elég lehet. Nagyobb értékű vagy kockázatú esetekben viszont nem javasoljuk.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="megved-e-minden-esetben-a-signowise-alairas?">
                      <Accordion.Item eventKey="17"><Accordion.Header>
                        Megvéd-e minden esetben a SignoWise aláírás?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="17" className="answer">
                        <div>
                          <p>Nem, semmilyen aláírás sem véd meg minden esetben. Amennyiben azt gondoljuk, hogy partnerünk nem megbízható, gondoljuk át, akarunk-e vele szerződni. Amennyiben át akar verni, minden aláírás esetén meg tudja tenni, még akkor is, ha közjegyzői okiratot írunk alá vele.</p>
                          <p>Hiába nyerjük meg a pert évek alatt, ha a másik fél megszegi az aláírt szerződést.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <h5>IT Biztonság</h5>
                    <div className="faq__inner--line" id="hogy-veditek-a-dokumentumot">
                      <Accordion.Item eventKey="18"><Accordion.Header>
                        Hogy véditek a dokumentumot?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="18" className="answer">
                        <div>
                          <p>A dokumentum tökéletesen védett a módosítások és aláírás utáni változások ellen, továbbá elválaszthatatlanul tartalmazza a minősített időbélyeget is, valamint a titkosított biometrikus aláírás részletes, írásszakértő által is vizsgálható adatait.</p>
                          <p>A dokumentum érvényessége könnyen ellenőrizhető pl. Adobe Acrobat Readerben is.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="hogy-veditek-a-biometrikus-alairast">
                      <Accordion.Item eventKey="19"><Accordion.Header>
                        Hogy véditek a biometrikus aláírást?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="19" className="answer">
                        <div>
                          <p>Az aláírás-biometria teljes, részletes tartalma titkosítva és elválaszthatatlanul kerül csatolásra minden aláírt dokumentumhoz. A biometrikus adatok egy egyedi titkosító kulccsal kerülnek titkosításra. Ez a titkosító kulcs egy bizalmi szolgáltatónál, a Microsec Zrt.-nél van letétben, az elérése szigorúan kontrollált és naplózott.</p>
                          <p>Biometrikus aláírási adatokat csak bírósági kérésre titkosítunk ki.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="hogy-veditek-az-internetes-kommunikaciot">
                      <Accordion.Item eventKey="20"><Accordion.Header>
                        Hogy véditek az internetes kommunikációt?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="20" className="answer">
                        <div>
                          <p>A kommunikáció a kliens és a szerver között titkosított HTTPS csatornán zajlik. Integrált működés esetén a szerver-szerver kommunikáció során a hívó felet API kulcs azonosítja. A rendszer felhasználóit pedig felhasználónév-jelszó párossal azonosítjuk.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <h5>Adatvédelem</h5>
                    <div className="faq__inner--line" id="gdpr-nak-hogyan-felel-meg-a-signowise">
                      <Accordion.Item eventKey="21"><Accordion.Header>
                        GDPR-nak hogyan felel meg a SignoWise?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="21" className="answer">
                        <div>
                          <p>A SignoWise természetesen megfelel a GDPR előírásainak.</p>
                          <p>A főbb tudnivalók: az ügyfelet tájékoztatni kell a SignoWise használatáról. Az aláírató cég az adatkezelő, míg a SignoWise-t üzemeltető Cursor Insight az adatfeldolgozó.</p>
                          <p>Az adatfeldolgozói tájékoztatót itt éred el. (link)</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="meddig-taroljatok-az-alairt-vagy-alairando-dokumentumot">
                      <Accordion.Item eventKey="22"><Accordion.Header>
                        Meddig tároljátok az aláírt vagy aláírandó dokumentumot?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="22" className="answer">
                        <div>
                          <p>Az aláírás után a felhasználónak számos módja van a kész, aláírt dokumentum mentésére.</p>
                          <p>Például, az integrált SignoWise az aláírás elkészülte után automatikusan átadja a dokumentumot, és egyben törli is azt a SignoWise rendszerből.</p>
                          <p>Word plugin és mappás aláírás esetén beállítható a weboldal Beállítások menüpontjában, hogy mennyi idő után kerüljön a dokumentum automatikusan törlésre.</p>
                          <p>Az aláírómappákból természetesen kézzel is törölhetőek a dokumentumok.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                  </Accordion>
                </Tab>
                <Tab eventKey="gyik" title="Felhasználói, technikai útmutatók">
                  <Accordion activeKey={activeAccordion} onSelect={e => setActiveAccordion(e)}>
                    <h5>Általános</h5>
                    <div className="faq__inner--line" id="hogyan-mukodik-a-rendszer">
                      <Accordion.Item eventKey="0"><Accordion.Header>
                        Hogyan működik a rendszer?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="0" className="answer">
                        <div>
                          <p>A SignoWise rendszer egy aláírandó és már aláírásra kész, adatokkal már feltöltött PDF-et tud fogadni, amit aztán aláírva a megfelelő védelemmel és hitelesítéssel ellátva, szintén PDF-ként ad vissza.</p>
                          <p>Az aláírandó PDF-t a leggyakrabban a cég dokumentumgeneráló rendszere adja, míg az aláírt PDF-t a cég archiváló rendszere fogadja.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="mit-tartalmaz-a-signowise-szolgaltatas">
                      <Accordion.Item eventKey="1"><Accordion.Header>
                        Mit tartalmaz a SignoWise szolgáltatás?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="1" className="answer">
                        <div>
                          <p>A SignoWise egy teljeskörű biometrikus e-aláíró szolgáltatás. Tartalmazza az aláíráshoz szükséges minősített időbélyeget is, továbbá a szolgáltatás minősített tanúsítványát is. A minősített tanúsítvány egy online nyilvántartott tanúsítvány. A minősített tanúsítvánnyal aláírt dokumentum azonnal és bármikor ellenőrizhető pl. az ingyenes Adobe Acrobat Readerben is.</p>
                          <p>Ezekkel Neked nem kell foglalkoznod.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <h5>Technikai kérdések</h5>
                    <div className="faq__inner--line" id="hogyan-tudok-dokumentumot-alairatni-illetve-az-alairt-dokumentumot-visszakapni">
                      <Accordion.Item eventKey="2"><Accordion.Header>
                        Hogyan tudok dokumentumot aláíratni, illetve az aláírt dokumentumot visszakapni?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="2" className="answer">
                        <div>
                          <p>A rendszer működéséhez szükség van egy aláírandó PDF dokumentumra (aláírómezőkkel), amit aláírás után a rendszer visszaad.</p>
                          <p>A dokumentum aláírására több módszer is rendelkezésre áll, amik kombinálhatók is.</p>
                          <ol>
                          <p>1. Microsoft Wordből a SignoWise Word pluginnel közvetlenül előkészíthető tetszőleges Word file aláírásra, azaz pl. beszúrhatsz aláírómezőket, és egy kattintással kezdeményezheted az aláírást. Az aláírt dokumentumot az aláírófelületről letöltheted, mentheted.</p>
                          <p>2. Az aláírásra kész file a felhasználó Aláírandó mappájába is elhelyezhető. A mappa elérhető regisztráció és bejelentkezés után. A mappán belül található dokumentumon az aláírást akár más kolléga is elvégezheti, akár más helyszínen is. Az aláírt PDF automatikusan a felhasználó Aláírt dokumentumok mappájába kerül, ahonnan letölthető, menthető.</p>
                          <p>3. A SignoWise automatikus integrációt is lehetővé tesz. Ehhez csupán az szükséges, hogy a dokumentumgeneráló rendszerben (több rendszer esetén minden rendszerben külön) elhelyezzünk a nyomtatás gomb mellé egy "SignoWise rendszerben aláír" gombot. Erre kattintva meghívandó egy általunk biztosított egyszerű feltöltő programkód, ami automatikusan meghívja a rendszerünket a dokumentummal.</p>
                          </ol>
                          <p>Az aláírt dokumentum a rendszerünkből letölthető egy másik egyszerű programkóddal. A két említett mintakód SignoWise integráció kérdésnél található.</p>
                          <p>A Word plugin és az aláírómappák használatához nem kell IT integráció. A SignoWise automatikus integrációja pedig mindössze pár órát vesz igénybe.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="melyik-a-szamomra-legpraktikusabb-felhasznalas">
                      <Accordion.Item eventKey="melyik-a-szamomra-legpraktikusabb-felhasznalas"><Accordion.Header>
                        Melyik a számomra legpraktikusabb felhasználás (Word plugin, aláíró mappa vagy integrált)?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="melyik-a-szamomra-legpraktikusabb-felhasznalas" className="answer">
                        <div>
                          <p>Amennyiben van dokumentumgeneráló és/vagy -tároló rendszer, akkor érdemes integrálni azokat. Ha előtte kipróbálnád a rendszert, akkor azt megteheted pl. az aláírómappás megoldással is.</p>
                          <p>Az aláíró mappák az integrált és a Word plugin megoldással is kompatibilisek.</p>
                          <p>Az aláírómappa szintén célszerű lehet, ha más eszközön akarsz aláírni, mint ahol a dokumentumot előállítottad, illetve akkor is, ha más helyszínen, és esetleg más kolléga írna alá (pl. a backoffice előkészítette a frontoffice-ban dolgozó kollégának a szerződést).</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="milyen-pdf-formatum-szukseges">
                      <Accordion.Item eventKey="4"><Accordion.Header>
                        Milyen PDF formátum szükséges?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="4" className="answer">
                        <div>
                          <p>A rendszer szabványos PDF formátumot használ, szabványos PDF aláírómezőkkel.</p>
                          <p>A visszaadott, aláírt PDF file is szabványos, és megnyitható például Adobe Acrobat Readerben.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="hogy-tudok-alairomezoket-letrehozni">
                      <Accordion.Item eventKey="5"><Accordion.Header>
                        Hogy tudok aláírómezőket létrehozni?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="5" className="answer">
                        <div>
                          <p>A SignoWise aláírandó file-jaiban értelemszerűen kell legyen egy (vagy több) aláírómező.</p>
                          <p>A SignoWise szabványos PDF formátummal működik, szabványos PDF aláírómezőkkel.</p>
                          <p>Ha PDF dokumentumgeneráló rendszert használsz, ott ez nem okozhat gondot, a sablonba be kell illeszteni az aláíró mező(ke)t.</p>
                          <p>Microsoft Word-ben pedig a SignoWise Word pluginnel tudod egy kattintással elhelyezni az aláírómezőt.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <h5>SignoWise Word plugin</h5>
                    <div className="faq__inner--line" id="hogy-mukodik-a-signowise-word-plugin">
                      <Accordion.Item eventKey="hogy-mukodik-a-signowise-word-plugin"><Accordion.Header>
                        Hogy működik a SignoWise Word plugin?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="hogy-mukodik-a-signowise-word-plugin" className="answer">
                        <div>
                          <p>Le kell tölteni és telepíteni a SignoWise Word plugint.</p>
                          <p>A plugin segítségével elhelyezhető tetszőlegesen a kellő számú aláírómező, és az aláírás is kezdeményezhető.</p>
                          <p>Díjmentes próbához elég egy díjmentes regisztráció, az éles használathoz regisztráció és előfizetés szükséges.</p>
                          <p>A plugin használatához be kell jelentkezni a weblapon megadott email-címmel és jelszóval.</p>
                          <p>A Word plugin használatához nem szükséges semmilyen IT integráció.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="milyen-microsoft-word-verziokkal-hasznalhato-a-signowise-word-plugin">
                      <Accordion.Item eventKey="7"><Accordion.Header>
                        Milyen Microsoft Word verziókkal használható a SignoWise Word plugin?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="7" className="answer">
                        <div>
                          <p>Microsoft Office 2013, 2016 és 2019.</p>
                          <p>A Microsoft Office 365 online megvalósításon dolgozunk.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <h5>Aláírómappák</h5>
                    <div className="faq__inner--line" id="hogyan-mukodnek-az-alairo-mappak">
                      <Accordion.Item eventKey="hogyan-mukodnek-az-alairo-mappak"><Accordion.Header>
                        Hogyan működnek az aláíró mappák?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="hogyan-mukodnek-az-alairo-mappak" className="answer">
                        <div>
                          <p>Minden felhasználó kap két mappát: egy Aláírandó és egy Aláírt mappát, amik regisztráció és bejelentkezés után érhetőek el.</p>
                          <p>Igény szerint több mappapár is létrehozható, pl. több iroda esetén irodánként külön, hogy az egyes irodák dokumentumai könnyebben megkülönböztethetők legyenek.</p>
                          <p>Az Aláírandó mappában lévő, aláírásra alkalmas dokumentumok egy kattintással aláírhatók.</p>
                          <p>Aláírás után a kész dokumentumok átkerülnek automatikusan a megfelelő Aláírt mappába.</p>
                          <p>A mappák nagy előnye a kényelmes használat mellett, hogy semmilyen IT integráció nem szükséges használatukhoz.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <h5>Integrált SignoWise</h5>
                    <div className="faq__inner--line" id="hogy-mukodik-a-signowise-integracio">
                      <Accordion.Item eventKey="hogy-mukodik-a-signowise-integracio"><Accordion.Header>
                        Hogy működik a SignoWise integráció?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="hogy-mukodik-a-signowise-integracio" className="answer">
                        <div>
                          <p>A SignoWise rendszer tökéletesen és a felhasználók (aláírók, ügyintézők) számára is észrevétlenül integrálható a dokumentumgeneráló, -fogadó és -archiváló rendszerekhez.</p>
                          <p>Ehhez szükséges a hívó rendszer azonosításának beállítása, valamint együttműködő rendszerenként egy feltöltő vagy letöltő rutin a dokumentum aláírásra küldéséhez, illetve az aláírt dokumentum mentéséhez.</p>
                          <p>Akár az is járható, hogy csak a dokumentum aláírásra betöltés, vagy csak az aláírt dokumentum visszaadása történik automatizált integrációval, amennyiben ez a praktikus. Az aláírómappák segítségével manuálisan pótolható a hiányzó funkció.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="hogyan-azonosit-a-rendszer">
                      <Accordion.Item eventKey="10"><Accordion.Header>
                        Hogyan azonosítja a SignoWise a hívó rendszert?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="10" className="answer">
                        <div>
                          <p>Rendszerek közötti kommunikáció esetén a hívó rendszert API kulccsal azonosítja a SignoWise Cloud rendszer. API kulcsot generálni a SignoWise Cloud admin felületén lehet, bármikor lehetőség van új kulcs generálására és a korábban generált kulcsok érvényességének visszavonására.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="hogyan-allithato-be-ez-az-azonositas">
                      <Accordion.Item eventKey="11"><Accordion.Header>
                        Hogyan állítható be a hívó rendszer azonosítása?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="11" className="answer">
                        <div>
                          <p>Az API kulcsot több módon is át lehet adni. Használd azt, amelyiket a legkönnyebb megvalósítani a hívó rendszerben:</p>
                          <p>HTTP fejlécben: Az API kulcsot a X-SignoWise-Api-Key kulccsal kell megadni a fejlécben:</p>
                          <p>
                          <pre>
                            curl -H 'X-SignoWise-Api-Key: KULCS' 'https://www.signowise.hu/CreateDocument'</pre>
                          </p>
                          <p>URL paraméterként: Az API kulcsot a signowise_api_key paraméter értékeként kell átadni:</p>
                          <p>curl 'https://www.signowise.hu/CreateDocument?signowise_api_key=KULCS'</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="mi-az-alairando-dokumentumot-feltolto-mintakod">
                      <Accordion.Item eventKey="12"><Accordion.Header>
                        Mi az aláírandó dokumentumot feltöltő mintakód?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="12" className="answer">
                        <div>
                          <p>CreateDocument</p>
                          <p>Swagger leíró: <a href="https://demo.signowise.com/swagger/index.html" target="_blank" rel="noreferrer">https://demo.signowise.com/swagger/index.html</a></p>
                          <p>Minta C# kód:</p>
                          <pre>{`
    var client = new HttpClient();
    client.DefaultRequestHeaders.Add("X-SignoWise-Api-Key", "Your API key");
    var response = await client.PostAsync(
        $"{ServerUrl}/Server/CreateDocument",
        new StringContent(
            JsonConvert.SerializeObject(new
            {
                document = Convert.ToBase64String(pdfDocument)
            }),
            Encoding.Default,
            "application/json"
        )
    );

    var responseContent = await response.Content.ReadAsStringAsync();
    dynamic message = JObject.Parse(responseContent);
    string workflowId = message.workflowId;
                          `}</pre>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="mi-az-alairt-dokumentumot-letolto-mintakod">
                      <Accordion.Item eventKey="13"><Accordion.Header>
                        Mi az aláírt dokumentumot letöltő mintakód?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="13" className="answer">
                        <div>
                          <p>DownloadDocument</p>
                          <p>Swagger leíró: <a href="https://demo.signowise.com/swagger/index.html" target="_blank" rel="noreferrer">https://demo.signowise.com/swagger/index.html</a></p>
                          <p>Minta C# kód:</p>
                          <pre>{`
                            var client = new HttpClient();
                            client.DefaultRequestHeaders.Add("X-SignoWise-Api-Key", "Your API key");
                            var response = await client.PostAsync(
                                $"{ServerUrl}/Server/DownloadDocument",
                                new StringContent(
                                    JsonConvert.SerializeObject(new
                                    {
                                        workflowId = workflowId
                                    }),
                                    Encoding.Default,
                                    "application/json"
                                )
                            );

                            var responseContent = await response.Content.ReadAsStringAsync();
                            dynamic message = JObject.Parse(responseContent);
                            byte[] pdfDocument = Convert.FromBase64String(message.document);
                          `}</pre>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="signowise-altali-automatikus-push-alairt-dokumentum-feltolto-kod">
                      <Accordion.Item eventKey="15"><Accordion.Header>
                        SignoWise általi automatikus (Push) aláírt dokumentum feltöltő kód
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="15" className="answer">
                        <div>
                          <p>Aláírt dokumentumok esetén történhet az integráció úgy is, hogy a SignoWise rendszer automatikusan átadja a kész, aláírt dokumentumot a fogadó rendszer számára és nem szükséges az előző dokumentumletöltő hívás implementálása.</p>
                          <p>
                            E helyett a fogadó rendszernek implementálnia kell egy
                            <a
                              href="https://demo.signowise.com/swagger/index.html?urls.primaryName=Callback%20interface%20(server%20-%20server)"
                              target="_blank"
                              rel="noreferrer"
                            >
                              interfészt
                            </a>
                            két hívással és a SignoWise Cloud Admin felületén meg kell adni, hogy milyen publikus URL-en éri el ezt a fogadó interfészt a SignoWise Cloud rendszer.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="mappak">
                      <Accordion.Item eventKey="14"><Accordion.Header>
                        Mappák
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="14" className="answer">
                        <div>
                          <p>Az integráción keresztül beküldött dokumentumok is elérhetőek a SignoWise Cloud webes felületén az aláírómappákban is. Amennyiben az ügyfélnek több aláírómappája is van, akkor a dokumentum feltöltésekor metaadatként lehet megadni, hogy melyik mappába kerüljön a dokumentum.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <h5>Kompatibilitás, aláíróeszközök</h5>
                    <div className="faq__inner--line" id="mar-van-minositett-e-alairasom-hasznalhatom-azt-a-signowise-zal">
                      <Accordion.Item eventKey="16"><Accordion.Header>
                        Már van minősített e-aláírásom. Használhatom azt a SignoWise-zal?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="16" className="answer">
                        <div>
                          <p>Igen. A PDF dokumentum a SignoWise aláírás előtt és után is aláírható minősített e-aláírással a megszokott módon, a SignoWise rendszeren kívül.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="milyen-tablet-megfelelo-az-alairasra">
                      <Accordion.Item eventKey="17"><Accordion.Header>
                        Milyen tablet megfelelő az aláírásra?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="17" className="answer">
                        <div>
                          <p>A megoldás szinte bármilyen kézi aláírás rögzítésre (tollal vagy styllusszal) és webes tartalom megjelenítésre alkalmas eszközzel használható.</p>
                          <p>Megfelelőek az iPadek, Android tabletek, tablet PC-k, továbbá hagyományos PC egy hozzá csatlakoztatott pen display eszközzel.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="mik-a-leggyakrabban-hasznalt-tabletek">
                      <Accordion.Item eventKey="mik-a-leggyakrabban-hasznalt-tabletek"><Accordion.Header>
                        Mik a leggyakrabban használt tabletek?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="mik-a-leggyakrabban-hasznalt-tabletek" className="answer">
                        <div>
                          <p>A leggyakoribb a már meglevő PC és egy pen digitizer eszköz, vagy pedig egy különálló tablet, pl. iPad:</p>
                          <ul>
                          <p>Amennyiben működik a szerződés előállítására szolgáló szoftver a tableten (iPad), akkor ezt a tabletet érdemes az aláírásra is használni.</p>
                          <p>Ha a meglevő PC-n levő használat a cél, akkor egy kiegészítő pen display eszköz a praktikus.</p>
                          </ul>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="van-tabletem-de-nincs-tollam-milyet-vegyek">
                      <Accordion.Item eventKey="19"><Accordion.Header>
                        Van tabletem, de nincs tollam. Milyet vegyek?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="19" className="answer">
                        <div>
                          <p>A SignoWise használatához az ún. aktív tollakat javasoljuk.</p>
                          <p>A megfelelő toll használata a következők miatt fontos: </p>
                          <ul>
                          <li>- A megfelelő toll nélkül az aláírási érzet nem teljesen hasonlít a hagyományos tollal történő aláírásra. </li>
                          <li>- A megfelelő toll nélkül a tabletek nem tudják a kezet, illetve ujjakat megkülönböztetni a tolltól, így romlik az adatminőség.</li>
                          <li>- A megfelelő toll nélkül a készülék nem feltétlenül képes rögzíteni a nyomásértéket és a dőlésszöget.</li>
                          </ul>
                          <p>Amennyiben nem megfelelő tollat, vagy ujjat használunk az aláíráshoz, az ugyanakkor nem jelenti automatikusan a jogi meg nem felelést, de csökkenti az írásszakértői vizsgálhatóságát az aláírásnak. Alacsonyabb kockázatú szerződések esetén ez nem feltétlenül okoz gondot.</p>

                          A következő tollak léteznek
                          <ul>
                            <li><b>Aktív toll:</b> Aktív tollal lehet a legjobb aláírási adatminőséget elérni. Az eszköz képes rögzíteni nyomásadatot, illetve bizonyos gyártók esetében dőlésszögadatot is. A legtöbb eszköznél a palm rejection (ujj megkülönböztetése) is megoldott aktív tollak esetén (azaz ha kézzel/tenyérrel hozzáérünk a kijelzőhöz az aláírás során, az nem okoz problémát). Az alkalmazott technológia függvényében vagy a tollba épített akkumulátor vagy a kijelző elektromágneses mezeje biztosítja a toll energiaellátását. Akkumulátoros esetben a tollat adott időközönként (~10-20 óra használat után) tölteni kell. Mivel a működéshez nem csak speciális tollra, hanem speciális kijelzőre is szükség van, ezért ez a technológia csak bizonyos toll és eszköz párosok esetén érhető el (Apple iPencil és az azt támogató iPadek, Samsung S-Pen és S-Pen kompatibilis Samsung eszközök, Huawei M-Pen és az ezzel kompatibilis Huawei telefonok, Microsoft Surface Pen és ezzel kompatibilis Microsoft Surface-ek, Lenovo Active Pen és kompatibilis Lenovo tabletek, 2 in 1 Pc-k, Wacom digitalizáló táblák és pen displayek Wacom tollakkal).</li>
                            <li><b>Kapacitív toll:</b> Az emberi ujjat imitáló, olcsó toll. Bármilyen érintőkijelzős eszközzel kompatibilis. Nyomás és dőlésszög adatot nem tud generálni, illetve az ujjal való érintéstől sem különböztethető meg a tabletek számára. Fontos, hogy az aláírás során kézzel ne érjen hozzá a felhasználó az eszköz felületéhez. </li>
                            <li><b>Aktív kapacitív toll:</b> Bármilyen érintőkijelzős eszközzel kompatibilis. Az aktív kapacitív technológiának köszönhetően kisebb a toll hegye, így az aláírásérzet jobban közelíti a valódi tollal történő aláírást, de az aktív technológia egyben azt is jelenti, hogy a tollat adott időközönként (~10-15 óra használat után) tölteni kell. Itt sem áll rendelkezésre nyomás- és dőlésszögadat. és a technológia itt sem tud különbséget tenni a toll és a kézfej között (nincs palm rejection), így fontos, hogy az aláírás során kézzel ne érjen hozzá a felhasználó az eszköz felületéhez.</li>
                          </ul>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <h5>Egyéb technikai tudnivalók</h5>
                    <div className="faq__inner--line" id="szuksegem-van-internetre-a-rendszer-mukodesehez">
                      <Accordion.Item eventKey="20"><Accordion.Header>
                        Szükségem van Internetre a rendszer működéséhez?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="20" className="answer">
                        <div>
                          <p>Igen, folyamatosan.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="tudok-tobb-alkalmazottat-is-regisztralni">
                      <Accordion.Item eventKey="21"><Accordion.Header>
                        Tudok több alkalmazottat is regisztrálni?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="21" className="answer">
                        <div>
                          <p>Dolgozunk rajta. Egyelőre az aláíró mappák segítségével és közös login-nal tudja használni több kolléga is a rendszert.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                    <div className="faq__inner--line" id="mi-a-Whitelabel-integracio">
                      <Accordion.Item eventKey="22"><Accordion.Header>
                        Mi a Whitelabel integráció?
                      </Accordion.Header></Accordion.Item>
                      <Accordion.Collapse eventKey="22" className="answer">
                        <div>
                          <p>Lehetővé teszi a SignoWise szolgáltatás önállóan történő, teljes testreszabását. Minden szolgáltatási elem és az aláírófelület is testre szabható úgy, hogy az aláíró, de még a kollégáid sem fognak tudni a SignoWise szolgáltatásról.</p>
                        </div>
                      </Accordion.Collapse>
                    </div>
                  </Accordion>
                </Tab>
              </Tabs>
              <QuestionModal
                showModal={showQuestionModal}
                setShowModal={setShowQuestionModal}
              />
              <div className="btn-container">
                <button
                  className="btn"
                  onClick={() => setShowQuestionModal(true)}
                >
                    Kérdésem van <img src={arrowBtn} alt="arrow-btn" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;
