import { Link as RouterLink } from 'react-router-dom';
import { Link } from 'react-scroll';
import logoBlack from '../../images/logo-black.svg';
import arrowBtn from '../../images/arrow-btn.svg';
import video from '../../signo_wise.mp4';

const Hero = () => {
  return (
    <section className="hero">
      <video className="video" autoPlay muted>
        <source src={video} type="video/mp4" />
      </video>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <img src={logoBlack} className="logo" alt="logo" />
            <div className="hero__text">
              <h3>Biometrikus e-aláírás</h3>
              <h4>Több mint kétmillió magyar felhasználó</h4>
              <ul>
                <li>Takaríts meg szerződésenként akár 15 percet és 1000 Ft-ot papírmentes személyes ügyintézéssel!</li>
                <li>Több mint 2 millió OTP banki ügyfél által is használt elektronikus aláírási termékünk a KKV-d számára is elérhető.</li>
                <li>Érintőképernyőn aláírni pont olyan, mint papíron, így az ügyfeleid számára gördülékeny az átállás.</li>
              </ul>
              <RouterLink to="/register">
                <button className="btn">
                  Kipróbálom díjmentesen <img src={arrowBtn} alt="arrow-btn" />
                </button>
              </RouterLink>
            </div>
          </div>
          <div className="col-lg-6">
          </div>
        </div>
        <div className="row">
          <Link to="advantages"
            smooth={false}
            offset={-100}
            className="scroll"
            isDynamic={true}
          >
            <div className="scroll-downs">
              <div className="mousey">
                <div className="scroller"></div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Hero;
