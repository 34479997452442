import axios from 'axios';
import { BASE_URL, getAxiosConfig } from './common.js'

const API_URL = `${BASE_URL}/management/`

const getUnsignedDocuments = () => {
  return axios
    .get(
      API_URL + 'getUnsignedDocuments',
      getAxiosConfig())
    .then((response) => {
        return response.data;
      });
};

const getFinishedDocuments = () => {
    return axios
      .get(
        API_URL + 'getFinishedDocuments',
        getAxiosConfig())
      .then((response) => {
          return response.data;
        });
  };

const downloadDocument = (workflowId) => {
  return axios
    .post(
      API_URL + 'downloadDocument',
      { workflowId },
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const createDocument = (fileName, userName, document) => {
  return axios
    .post(
      API_URL + 'createDocument',
      {
        document,
        metaData: [{
          key: 'fileName',
          value: fileName
        },{
          key: 'userName',
          value: userName
        }]
      },
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const initializeDocument = (workflowId) => {
  return axios.post(
      API_URL + 'initializeDocument', {
        workflowId
      },
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const terminateDocument = (workflowId) => {
  return axios.post(
      API_URL + 'terminateDocument',
      { workflowId },
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const services = {
  createDocument,
  getUnsignedDocuments,
  getFinishedDocuments,
  downloadDocument,
  terminateDocument,
  initializeDocument,
}

export default services;
