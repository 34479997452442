
import { Modal, Button } from 'react-bootstrap';

const SwitchModeModal = (props) => {
  const { isDemo, showModal, setShowModal} = props;

  return (
    <Modal show={showModal} centered>
      <Modal.Header>
        <Modal.Title>
          {isDemo ? 'Teszt mód' : 'Érvényes mód'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isDemo
          ? 'Az alkalmazás díjmentes tesztaláírásokkal működik'
          : 'Az alkalmazás az előfizetés szerint, ' +
            'jogilag érvényes aláírásokkal működik'
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => setShowModal(false)}>
          Rendben
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SwitchModeModal;
