import React, { useReducer } from 'react';
import { Switch, Route } from 'react-router-dom';

import '../App.scss';
import Header from '../sections/header/Header';
import LoggedInHeader from '../sections/header/LoggedInHeader';
import Footer from '../sections/footer/Footer';

import Login from './Login';
import Register from './Register';
import ConfirmEmail from './ConfirmEmail';
import Forgot from './Forgot';
import ResetPassword from './ResetPassword';
import NotFound from './NotFound';
import ShouldBeLoggedIn from './ShouldBeLoggedIn';
import Dashboard from './Dashboard';
import Landing from './Landing';

import Cart from './Cart';
import { cartState, cartReducer, CartContext } from '../reducers/cartReducer';
import authService from '../services/auth';


function Template() {
  const [state, dispatch] = useReducer(cartReducer, cartState);

  const currentUser = authService.getCurrentUser();

  const getHeader = () => {
    return currentUser ? <LoggedInHeader/> : <Header/>;
  }

  return (
    <CartContext.Provider
      value={{
        state,
        dispatch
      }}>
      {getHeader()}
      <Switch>
        <Route path="/" component={Landing} exact={true}/>
        <Route path="/register" component={Register}/>
        <Route path="/login" component={Login}/>
        <Route path="/confirmEmail/:code?/:email?" component={ConfirmEmail}/>
        <Route path="/forgot" component={Forgot}/>
        <Route path="/resetPassword/:code?/:email?" component={ResetPassword}/>
        {currentUser ? (
          <>
            <Route path="/cart" component={Cart}/>
            <Route path="/dashboard/:tab?" component={Dashboard}/>
          </>
        ) : (
          <>
            <Route path="/cart" component={ShouldBeLoggedIn}/>
            <Route path="/dashboard" component={ShouldBeLoggedIn}/>
          </>
        )}
        <Route path="" component={NotFound} />
      </Switch>
      <Footer/>
    </CartContext.Provider>
  );
}

  export default Template;
