import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import questionService from '../../services/question';
import authService from '../../services/auth';
import { secureFetch } from '../../libs/utils';
import { useFormFields } from '../../libs/hooks';


const QuestionModal = (props) => {
  const { showModal, setShowModal} = props;

  const [ errorMessage, setErrorMessage ] = useState(null);

  const currentUser = authService.getCurrentUser();
  const defaultFormValue = {
    companyName: '',
    companySector: '',
    email: currentUser ? currentUser.email : '',
    phoneNumber: '',
    question: ''
  };
  const [ fields, handleFieldChange, setAll ] = useFormFields(defaultFormValue);

  const save = async () => {
    setErrorMessage(null);

    if (!fields.email) {
      setErrorMessage('Kérjük add meg az email címed!');
      return;
    }

    if (!fields.question) {
      setErrorMessage('Kérjük töltsd ki a kérdést!');
    }

    await secureFetch(
      () => questionService.question(fields),
      () => {
        setShowModal(false);
        setAll(defaultFormValue);
      },
      setErrorMessage,
      'Hiba a kérdés elküldése során'
    );
  };

  return (
    <Modal
      show={showModal}
      centered
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Üzenet küldése
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <div className="form__row">
            <span className="error">{errorMessage}</span>
          </div>
        )}
        <div className="form__row">
          <input
            id="companyName"
            placeholder="Cég neve"
            onChange={handleFieldChange}
            value={fields.companyName}
            required
          />
        </div>
        <div className="form__row">
          <input
            id="companySector"
            placeholder="Cég iparága pl. egészségügy"
            value={fields.companySector}
            onChange={handleFieldChange}
          />
        </div>
        <div className="form__row">
          <input
            id="email"
            placeholder="Email cím"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </div>
        <div className="form__row">
          <input
            id="phoneNumber"
            placeholder="Telefonszám"
            value={fields.phoneNumber}
            onChange={handleFieldChange}
          />
        </div>
        <div className="form__row">
          <textarea
            id="question"
            placeholder="Kérdés"
            value={fields.question}
            onChange={handleFieldChange}
            rows={5}
            required
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={!fields.email || !fields.question}
          onClick={() => save()}>
          Küldés
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default QuestionModal;
