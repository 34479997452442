import { Link as RouterLink } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { useState, useEffect } from 'react';

import Fade from 'react-reveal/Fade';
import swWord from '../../images/sw-word.svg';
import swCloud from '../../images/sw-cloud.svg';
import arrowBtn from '../../images/arrow-btn.svg';
import arrowBlue from '../../images/arrow-blue.svg';

const Guarentee = ({setActiveTab, activeTab, setActiveAccordion}) => {
  const [scrollTarget, setScrollTarget] = useState(null);

  useEffect(() => {
      if (scrollTarget && activeTab === 'gyik') {
        scroller.scrollTo(scrollTarget, {
          smooth: false,
          offset: -100,
          isDynamic: true
        });
        setScrollTarget(null);
      }
    },
    [scrollTarget, activeTab])


  return (
    <section className="guarantee">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="title">
              <h3>
                <Fade left>
                  Díjmentesen kipróbálható
                </Fade>
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="guarantee__side">
              <div className="bubble">
                <p>Microsoft Wordben írod a szerződéseket?</p>
              </div>
              <div className="download">
                <img src={swWord} alt="Signo Wise" />
                <ol>
                  <li>
                    Töltsd le az ingyenes SignoWise Word plugint
                  </li>
                  <li>
                    Regisztrálj és próbálj ki minden funkciót díjmentesen
                  </li>
                  <li>
                    Válassz előfizetést jogilag érvényes aláírás használatához
                  </li>
                </ol>
                <a href="/files/SignoWiseWordAddin.exe">
                  <button className="btn">
                    Letöltöm a Word plugint
                    <img src={arrowBtn} alt="arrow-btn" />
                    </button>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="guarantee__side">
              <div className="bubble">
                <p>Vállalati dokumentumgeneráló rendszert használsz?</p>
              </div>
              <div className="download">
                <img src={swCloud} alt="Signo Wise" />
                <ol>
                  <li>
                    Regisztrálj, és integráld legfeljebb pár órás munkával
                    az ingyenes API-t
                  </li>
                  <li>Próbálj ki minden funkciót díjmentesen</li>
                  <li>
                    Válassz előfizetést jogilag érvényes aláírás használatához
                  </li>
                </ol>
                <RouterLink to="/register">
                  <button className="btn">
                    Regisztrálok <img src={arrowBtn} alt="arrow-btn" />
                  </button>
                </RouterLink>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col">
            <div className="title">
              <h3>
                <Fade left>
                  Egyszerűen használható
                </Fade>
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="easy">
              <ul>
                <li>
                  <i className="fa fa-file-text-o" aria-hidden="true"></i>
                  <h5>1. HELYEZZ EL ALÁÍRÁSMEZŐT</h5>
                  <p>Véglegesítsd az ügyféldokumentumot úgy, ahogy szoktad</p>
                  <p>Helyezd el az aláírásmezőket egy-egy kattintással</p>
                  <p>
                    Újabb kattintással indítsd az aláírást Wordből vagy Cloud
                    megoldás esetén a saját rendszeredből
                  </p>
                </li>
                <li>
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                  <h5>2. ÍRJ ALÁ</h5>
                  <p>
                    Írj alá a tableted vagy a PC-d képernyőjén,
                    vagy akár egérrel
                  </p>
                  <p>
                    Az aláírást az online mappádból is indíthatod,
                    amit más gépről vagy mobil eszközről is elérsz
                  </p>
                </li>
                <li>
                  <i className="fa fa-cloud-download" aria-hidden="true"></i>
                  <h5>3. KÉSZEN IS VAGY</h5>
                  <p>
                    A minden fél által aláírt, a rendszerünk általunk
                    is hitelesített PDF dokumentumot elmentheted,
                    továbbküldheted
                  </p>
                  <p>
                    Az aláírt dokumentumokat az online mappádban is megtalálod
                  </p>
                  <p>
                    Cloud megoldás esetén a mentés és az archiválás
                    automatizálható
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <h4>Nézd meg videón is, hogyan működik</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="video">
              <iframe
                src="https://www.youtube-nocookie.com/embed/eENr2K-ujY4"
                title="YouTube video player"
                frameborder="0"
                allowfullscreen>
              </iframe>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="btn-container">
              <a
                className="btn"
                href="http://demo.signowise.hu"
                target="_blank"
                rel="noreferrer"
              >
                Aláírok egy mintadokumentumot <img src={arrowBtn} alt="arrow-btn" />
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="questions">
              <div className="questions__bottom">
                <h4>Tudástárunkban részletes útmutatókat találsz</h4>
                <btn className="link" onClick={() => {
                  setScrollTarget('hogy-mukodik-a-signowise-word-plugin');
                  setActiveTab('gyik');
                  setActiveAccordion('hogy-mukodik-a-signowise-word-plugin');
                }}>
                  SignoWise Word plugin <img src={arrowBlue} alt="Arrow" />
                </btn>
                <btn className="link" onClick={() => {
                  setScrollTarget('hogy-mukodik-a-signowise-integracio');
                  setActiveTab('gyik');
                  setActiveAccordion('hogy-mukodik-a-signowise-integracio');
                }}>
                  SignoWise Cloud integrálása
                  {' '}
                  <img src={arrowBlue} alt="Arrow" />
                </btn>
                <btn className="link" onClick={() => {
                  setScrollTarget('hogyan-mukodnek-az-alairo-mappak');
                  setActiveTab('gyik');
                  setActiveAccordion('hogyan-mukodnek-az-alairo-mappak');
                }}>
                  Drag&drop aláírómappák használata
                  {' '}
                  <img src={arrowBlue} alt="Arrow" />
                </btn>
                <btn className="link" onClick={() => {
                  setScrollTarget('melyik-a-szamomra-legpraktikusabb-felhasznalas');
                  setActiveTab('gyik');
                  setActiveAccordion('melyik-a-szamomra-legpraktikusabb-felhasznalas');
                }}>
                  Melyik megoldásra van szükségem?
                  {' '}
                  <img src={arrowBlue} alt="Arrow" />
                </btn>
                <btn className="link" onClick={() => {
                  setScrollTarget('mik-a-leggyakrabban-hasznalt-tabletek');
                  setActiveTab('gyik');
                  setActiveAccordion('mik-a-leggyakrabban-hasznalt-tabletek');
                }}>
                  Milyen aláíró eszközt használhatok?
                  {' '}
                  <img src={arrowBlue} alt="Arrow" />
                </btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Guarentee;
