import axios from 'axios';
import { BASE_URL, getAxiosConfig } from './common.js'

const API_URL = `${BASE_URL}/customOffer/`

const customOptions = (options) => {
  return axios
    .post(
      API_URL + 'customOptions',
      options,
      getAxiosConfig())
    .then((response) => {
        return response.data;
      });
};

const customSigningOffer = (signingOffer) => {
  return axios
    .post(
      API_URL + 'customSigningOffer',
      signingOffer,
      getAxiosConfig())
    .then((response) => {
        return response.data;
      });
};

const services = {
  customOptions,
  customSigningOffer
}

export default services;
