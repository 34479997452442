import React, { useState } from 'react';

import { secureFetch } from '../../libs/utils';
import SuccessModal from '../../components/modal/SuccessModal';
import authService from '../../services/auth';

export default function ConfirmEmailForm(props) {
  const {
    errorMessage,
    setErrorMessage,
    fields,
    handleFieldChange,
    handleConfirm
  } = props;

  const [emailResent, setEmailResent] = useState(false);

  const resendConfirmationEmail = async (e) => {
    e.preventDefault();
    await secureFetch(
      () => authService.resendConfirmationEmail(fields.email),
      () => {
        setEmailResent(true);

        //Clear the code field when a new code is sent
        handleFieldChange({
          target: {
            id: 'code',
            value: null
          }
        });
      },
      setErrorMessage,
      'Nem sikerült kiküldeni a megerősítő emailt'
    );
  };

  return (
    <>
      {errorMessage && (
        <div className="form__row">
          <span className="error">{errorMessage}</span>
        </div>
      )}
      <SuccessModal
        message="Elküldtük az e-mailt az új kóddal"
        timeout={2000}
        showModal={emailResent}
        setShowModal={setEmailResent}
      />
      <div className="form__row">
        Kérjük erősítse meg az email címét a kiküldött emailben található link
        megnyitásával, vagy az emailben található kód megadásával.
      </div>
      <div className="form__row">
        Amennyiben nem kaptad meg az emailt, vagy a kód már lejárt a Megerősítő
        email újraküldése gombra kattintva új kódot kérhetsz.
      </div>
      <div className="form__row">
        <input
          id="email"
          placeholder="E-mail cím"
          onChange={handleFieldChange}
          required
          value={fields.email}
        />
      </div>
      <div className="form__row">
        <input
          id="code"
          placeholder="Kód"
          onChange={handleFieldChange}
          value={fields.code}
        />
      </div>
      <div className="form__row">
        <button className="btn" onClick={handleConfirm}>
          Email cím megerősítése
        </button>
      </div>
      <div className="form__row">
        <button
          className="btn"
          onClick={resendConfirmationEmail}
          disabled={!fields.email}
        >
          Megerősítő email újraküldése
        </button>
      </div>
    </>);
};
