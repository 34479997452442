import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';

import SuccessModal from '../../components/modal/SuccessModal';
import info from '../../images/info.png';

import { secureFetch } from '../../libs/utils'
import userService from '../../services/user';
import authService from '../../services/auth';

function Helper() {
  const user = authService.getCurrentUser();

  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const disableHelp = async (value) => {
    await secureFetch(
      () => userService.setDisableHelp(value),
      () => {
        authService.updateUser({disableHelp : value});
        setShowSuccessModal(true);
      },
      setErrorMessage,
      'Nem sikerült kikapcsolni a súgót'
    );
  }

  return (
    <Accordion>
      <div className="dashboard__inner helper">
        <div className="dashboard__inner--row">
          <h2>Üdvözlünk a SignoWise alkalmazásban!</h2>
        </div>
        <div className="dashboard__inner--row">
          {errorMessage && (
            <div className="row">
              <span className="error">{errorMessage}</span>
            </div>
          )}
          <SuccessModal
            message={
              user.disableHelp
                ? 'Mostantól bejelentkezést követően nem fog megjelenni ' +
                  'a gyorstalpaló, de a menüből bármikor elérheted.'
                : 'Bejelentkezést követően ismét meg fog jelenni a gyorstalpaló'
            }
            timeout={2000}
            showModal={showSuccessModal}
            setShowModal={setShowSuccessModal}
          />
          <p>
            Köszönjük, hogy a papírmentes biometrikus e-aláírást választottad!
          </p>
          <p>
            Bizalmadat azzal viszonozzuk, hogy minden funkciót
            ki tudsz próbálni, mielőtt eldöntöd, hogy előfizetsz-e.
            Ezzel a gombbal <img src={info} className="info" alt="info" />
            {' '}
            tudsz váltani a díjmentes tesztaláírások
            és a jogilag érvényes aláírások között.
            Jogilag érvényesen aláírni előfizetéssel és aláírókreditekkel lehet.
          </p>
          <p>
            A SignoWise PDF dokumentumokat tud fogadni, amit aztán aláírva,
            a megfelelő védelemmel és hitelesítéssel ellátva,
            szintén PDF-ként ad vissza.
          </p>
          <p>
            Az aláírandó PDF előállítható Microsoft Wordben az ingyenes
            SignoWise Word plugin segítségével, vagy egy egynapos
            integrációt követően a céges dokumentumgeneráló rendszeredben is.
            Az aláírt PDF dokumentum letölthető közvetlenül
            az aláíró felületről, de a SignoWise API segítségével továbbítható
            a céged archiváló rendszere felé is.
          </p>
          <p>
            Az aláírásra beküldött, illetve a már aláírt
            dokumentumokat megtalálod az Aláírómappákban is.
          </p>
        </div>
        <div className="dashboard__inner--row check">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id="disableHelp"
              className="custom-control-input"
              required
              checked={user.disableHelp}
              onChange={(evt) => disableHelp(evt.target.checked)}
            />
            <label for="disableHelp" className="custom-control-label">
              Ne jelenjen meg többet
            </label>
          </div>
        </div>
      </div>
    </Accordion>
  );
}

export default Helper;
