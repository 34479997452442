import axios from 'axios';
import { BASE_URL, getAxiosConfig } from './common.js'

const API_URL = `${BASE_URL}/question/`

const question = (question) => {
  return axios
    .post(
      API_URL + 'question',
      question,
      getAxiosConfig())
    .then((response) => {
        return response.data;
      });
};

const services = {
  question
}

export default services;
