const BASE_URL = '';

const authHeader = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token };
  } else {
    return {};
  }
}

const getAxiosConfig = () => {
  return {
    headers: authHeader(),
    validateStatus: function (status) {
      return status >= 200 && status < 500;
    }
  }
}

export {
  BASE_URL,
  getAxiosConfig
};
