import { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const SuccessModal = (props) => {
  const { message, showModal, setShowModal, timeout } = props;

  useEffect(() => {
    if(showModal && timeout) {
      setTimeout(() => setShowModal(false), timeout);
    }
  }, [showModal, timeout, setShowModal]);

  return (
    <Modal show={showModal} centered>
      <Modal.Body>
        {message}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => setShowModal(false)}>
          Rendben
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SuccessModal;
