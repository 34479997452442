import React, { useState } from 'react';

import logo from '../images/logo-black.svg';

import { useFormFields } from '../libs/hooks';
import { secureFetch } from '../libs/utils'

import ResetPasswordForm from './sections/ResetPasswordForm';
import ResetPasswordSuccess from './sections/ResetPasswordSuccess';

import authService from '../services/auth';

function ResetPassword(props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: props.match.params.email !== undefined
    ? decodeURIComponent(props.match.params.email)
    : authService.getEmailAddress(),
    code: props.match.params.code !== undefined
      ? decodeURIComponent(props.match.params.code)
      : null,
    password: '',
    passwordRepeat: '',
  });

  const update = async (e) => {
    e.preventDefault();

    await secureFetch(
      () => authService.resetPassword(fields),
      () => setSuccess(true),
      setErrorMessage,
      'Hiba a jelszó módosítása során'
    );
  }

  return (
    <section className="register">
      <div className="container">
        <form className="form">
          <div className="form__logo">
            <img src={logo} className="logo" alt="logo" />
          </div>
          {success
            ? <ResetPasswordSuccess/>
            : <ResetPasswordForm
                fields={fields}
                handleFieldChange={handleFieldChange}
                errorMessage={errorMessage}
                update={update}
              />
          }
        </form>
      </div>
    </section>
  );
}

export default ResetPassword;
