import PasswordRequirements from '../../components/popup/PasswordRequirements';

const ResetPasswordForm = (props) => {
  const {errorMessage, fields, handleFieldChange, update} = props;

  return (<>
    <div className="form__row">
      <h3>Jelszó módosítása</h3>
    </div>
    {errorMessage && (
      <div className="form__row">
        <span className="error">{errorMessage}</span>
      </div>
    )}
    <div className="form__row">
      <input
        id="email"
        placeholder="E-mail cím"
        onChange={handleFieldChange}
        value={fields.email}
        required
      />
    </div>
    <div className="form__row">
      <input
        id="code"
        placeholder="Kód"
        value={fields.code}
        onChange={handleFieldChange}
        hidden={fields.code !== ''}
      />
    </div>
    <div className="form__row">
      <PasswordRequirements/>
      <input
        id="password"
        type="password"
        placeholder="Jelszó"
        value={fields.password}
        onChange={handleFieldChange}
        data-tip
        data-for="password"
      />
    </div>
    <div className="form__row">
      <input
        id="passwordRepeat"
        type="password"
        placeholder="Jelszó"
        value={fields.passwordRepeat}
        onChange={handleFieldChange}
      />
    </div>
    <div className="form__row">
      <button className="btn" onClick={update}>
        Jelszó módosítása
      </button>
    </div>
  </>);
};

export default ResetPasswordForm;
