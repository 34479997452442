import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import swWord from '../../images/sw-word.svg';
import swCloud from '../../images/sw-cloud.svg';
import arrowBtn from '../../images/arrow-btn.svg';

const Prices = () => {
  return (
    <section className="prices" id="prices">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="title">
              <h3>
                <Fade left>
                  Előfizetéses csomagok
                </Fade>
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6">
            <div className="prices__box word">
              <img src={swWord} alt="Signo Wise" className="prices__box--img" />
              <div className="price-main">
                <h3>19 900 Ft</h3>
                <span>+ ÁFA/év</span>
              </div>
              <div className="price-list">
                <ul>
                  <li>
                    Díjmentes regisztráció,
                    mellyel valamennyi funkció kipróbálható
                  </li>
                  <li>Microsoft Wordből indítható aláírás</li>
                  <li className="cancel">
                    Saját dokumentumgenerálóból indítható
                    aláírás (API integráció)
                  </li>
                  <li>
                    20 db aláírókredit jogilag is érvényes aláírásokhoz
                  </li>
                  <li>Emailes support</li>
                </ul>
                <a href="/files/SignoWiseWordAddin.exe">
                  <button className="btn">
                    Word plugin letöltése <img src={arrowBtn} alt="arrow-btn" />
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="prices__box cloud">
              <img src={swCloud} alt="Signo Wise" className="prices__box--img" />
              <div className="price-main">
                <h3>299 900 Ft</h3>
                <span>+ ÁFA/év</span>
              </div>
              <div className="price-list">
                <ul>
                  <li>
                    Díjmentes regisztráció,
                    mellyel valamennyi funkció kipróbálható
                  </li>
                  <li>Microsoft Wordből indítható aláírás</li>
                  <li>
                    Saját dokumentumgenerálóból indítható
                    aláírás (API integráció)
                  </li>
                  <li>
                    200 db aláírókredit jogilag is érvényes aláírásokhoz
                  </li>
                  <li>Emailes support</li>
                </ul>
                <Link to="/register">
                  <button className="btn">
                    Regisztrálok <img src={arrowBtn} alt="arrow-btn" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Prices;
