import React, { useState, useEffect } from 'react';
import subscriptionService from '../../services/subscription';

import { getDate } from '../../libs/utils';
import { downloadFile } from '../../libs/downloadHelper';
import { secureFetch } from '../../libs/utils';

function Orders() {
  const [errorMessage, setErrorMessage] = useState('');
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await secureFetch(
      () => subscriptionService.getOrders(),
      (response) => setOrders(response.orders),
      setErrorMessage,
      'Nem sikerült lekérdezni a korábbi rendeléseket'
    );
  }

  const downloadInvoice = async (id, orderNumber) => {
    await secureFetch(
      () => subscriptionService.downloadInvoice(id),
      (response) => downloadFile(`${orderNumber}.pdf`, response.invoice),
      setErrorMessage,
      'Nem sikerült letölteni a számlát'
    );
  }

  return (
    <div className="dashboard__inner--row">
      <h3>Korábbi rendeléseim</h3>
      {errorMessage && (
      <div className="row">
        <span className="error">{errorMessage}</span>
      </div>
      )}
      <table className="table order-table">
        <thead>
          <tr>
            <th>Tétel</th>
            <th>Időpont</th>
            <th>Státusz</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            orders.map(order =>(
              <tr>
                <td>{order.orderNumber}</td>
                <td>{getDate(order.createdAt)}</td>
                <td>{order.payedAt ? 'Teljesített': 'Függőben lévő'}</td>
                <td>
                  <button
                    className="btn"
                    onClick={() => downloadInvoice(order.id, order.orderNumber)}
                  >
                    {order.payedAt ? 'Letöltöm a számlát' : 'Letöltöm a díjbekérőt'}
                  </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
}

export default Orders;
