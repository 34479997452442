import axios from 'axios';

import { BASE_URL, getAxiosConfig } from './common.js'

const API_URL = `${BASE_URL}/user/`

const changePassword = (fields) => {
  return axios
    .post(
      API_URL + 'changePassword',
      fields,
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
}

const deleteUser = () => {
  return axios
    .post(
      API_URL + 'deleteUser',
      {},
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
}

const saveBillingAddress = (address) => {
  return axios
    .post(
      API_URL + 'saveBillingAddress',
      address,
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const getBillingAddress = () => {
  return axios
    .get(
        API_URL + 'getBillingAddress',
        getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const getConfiguration = () => {
  return axios
    .get(
      API_URL + 'getConfiguration',
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
}

const updateConfiguration = (configuration) => {
  return axios
    .post(
      API_URL + 'updateConfiguration',
      configuration,
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });
};

const setDisableHelp = (disableHelp) => {
  return axios
    .post(
      API_URL + 'setDisableHelp',
      { disableHelp },
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });

}

const setMode = (isDemo) => {
  return axios
    .post(
      API_URL + 'setMode',
      { isDemo },
      getAxiosConfig())
    .then((response) => {
      return response.data;
    });

}

const services = {
  changePassword,
  deleteUser,
  getBillingAddress,
  saveBillingAddress,
  getConfiguration,
  updateConfiguration,
  setDisableHelp,
  setMode
};

export default services;
