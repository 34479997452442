
import React, { useState, useEffect, useCallback } from 'react';
import { Accordion } from 'react-bootstrap';
import { usePageVisibility } from 'react-page-visibility';

import { downloadFile } from '../../libs/downloadHelper';
import { getDate, getMeta, secureFetch } from '../../libs/utils';

import documentService from '../../services/documents';

function SignedDocuments() {
  const [documents, setDocuments] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const refresh = useCallback(async() => {
    await secureFetch(
      () => documentService.getFinishedDocuments(),
      (result) => setDocuments(result.sessions),
      setErrorMessage,
      'Nem sikerült lekérdezni a dokumentumokat'
    );
  }, [setDocuments, setErrorMessage]);

  const downloadDocument = async (fileName, workflowId) => {
    await secureFetch(
      () => documentService.downloadDocument(workflowId),
      (result) => downloadFile(
        `${fileName || `${workflowId}.pdf`}`,
        result.document),
      setErrorMessage,
      'Nem sikerült letölteni a dokumentumot'
    );
  }

  const deleteDocument = async(workflowId) => {
    await secureFetch(
      () => documentService.terminateDocument(workflowId),
      () => refresh(),
      setErrorMessage,
      'Nem sikerült törölni a dokumentumot'
    );
  }

  const isVisible = usePageVisibility();
  useEffect(() => {
    if (isVisible) {
      refresh();
    }
  }, [refresh, isVisible]);

  return (
    <Accordion>
      <div className="dashboard__inner">
        <div className="dashboard__inner--row docs">
          <h2>Aláírt dokumentumok</h2>
        </div>
        <div className="dashboard__inner--row docs">
          {errorMessage && (
            <div className="row">
              <span className="error">{errorMessage}</span>
            </div>
          )}
          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <button className="btn" onClick={refresh}><i className="fa fa-refresh" aria-hidden="true"></i> Frissít</button>
            </div>
          </div>
          <div className="row">
            <table className="table">
              <thead>
                <tr>
                  <th>Fájlnév</th>
                  <th>Feltöltve</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {documents.map((document) => (
                  <tr>
                    <td>{getMeta(document, 'fileName')}</td>
                    <td>{getDate(document.createdAt)}</td>
                    <td>
                      <button
                        className="btn"
                        onClick={() => downloadDocument(
                          getMeta(document, 'fileName'),
                          document.workflowId)}>
                        <i className="fa fa-download" aria-hidden="true"></i>
                        Letölt
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn--delete"
                        onClick={() => deleteDocument(document.workflowId)}>
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                        Töröl
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Accordion>
  );
}

export default SignedDocuments;
