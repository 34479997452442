import Slider from 'react-slick';
import Fade from 'react-reveal/Fade';
import kisflavi from '../../images/kisfalvi_bence.jpeg';
import erdosi from '../../images/erdosi_peter.jpeg';
import bodnar from '../../images/bodnar_balazs.jpeg';
import dajkaViktor from '../../images/dajka_viktor.png';
import iconOtp from '../../images/icon-otp.png';
import iconOtpTwo from '../../images/icon-otp-two.png';
import iconAndoc from '../../images/icon-andoc.png';

const About = () => {

  const sliderSettings = {
    dots: true,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true
        }
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false
        }
      }
    ]
  };

  return (
    <section className="about">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="title">
              <h3>
                <Fade left>
                  Rólunk mondták
                </Fade>
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <Slider {...sliderSettings}>
            <div className="card">
              <div className="card__image">
                <img src={kisflavi} alt="kisflavi bence" />
              </div>
              <div className="card__icon">
                <img src={iconOtpTwo} alt="OTP" />
              </div>
              <div className="card__text">
                <p className="card__text--name">
                  Kisfalvi Bence
                </p>
                <p className="card__text--description">
                  Üzletfejlesztési Igazgató
                </p>
                <span className="card__text--icon" />
                <p className="card__text--quote-style">
                  A Cursor Insight csapata határidőre leszállította a kritikus banki rendszernek minősülő SignoWise biometrikus e-aláírási megoldást, amit azóta is közel 400 fiókunkban használunk.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card__image">
                <img src={erdosi} alt="erdősi péter" />
              </div>
              <div className="card__icon">
                <p>Magyar Elektronikus Aláírás Szövetség</p>
              </div>
              <div className="card__text">
                <p className="card__text--name">
                  Erdősi Péter
                </p>
                <p className="card__text--description">
                  Alelnök
                </p>
                <span className="card__text--icon" />
                <p className="card__text--quote-style">
                  A SignoWise a biometrikus aláírás és a PKI technológia kombinációjával egy praktikusan használható e-aláírási megoldást alkot.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card__image">
                <img src={bodnar} alt="bodnár balázs" />
              </div>
              <div className="card__icon">
                <img src={iconOtp} alt="OTP" />
              </div>
              <div className="card__text">
                <p className="card__text--name">
                  Bodnár Balázs
                </p>
                <p className="card__text--description">
                  Back Office Vezető
                </p>
                <span className="card__text--icon" />
                <p className="card__text--quote-style">
                  A SignoWise-zal már évek óta megbízhatóan írnak alá ingatlan közvetítőink és ügyfeleink a terepen, összesen közel 1000 darab tableten.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card__image">
                <img src={dajkaViktor} alt="Dajka Viktor" />
              </div>
              <div className="card__icon">
                <img src={iconAndoc} alt="Andoc" />
              </div>
              <div className="card__text">
                <p className="card__text--name">
                  Dajka Viktor
                </p>
                <p className="card__text--description">
                  Operatív Igazgató
                </p>
                <span className="card__text--icon" />
                <p className="card__text--quote-style">
                  A SignoWise integrációja az Andoc dokumentumkezelő rendszerhez gyors és problémamentes volt.
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default About;
