import React, { useState } from 'react';
import { useHistory } from 'react-router';

import ConfirmModal from '../../components/modal/ConfirmModal';

import userService from '../../services/user';
import authService from '../../services/auth';
import { secureFetch } from '../../libs/utils'

function DeleteAccount() {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const history = useHistory();

  const confirmCallback = (result) => {
    if (result) {
      handleAccountDelete();
    }
  }

  const handleAccountDelete = async() => {
    await secureFetch(
      () => userService.deleteUser(),
      () => {
        setSuccessMessage('Fiók sikeresen törölve');
        authService.logout();
        history.push('/');
      },
      setErrorMessage,
      'Nem sikerült törölni a felhasználói fiókot'
    );
  };

  return (
    <>
      <div className="dashboard__inner--row">
        <h3>Fiók törlése</h3>
        <ConfirmModal
          title={
            'Biztosan törlöd a fiókot?'
          }
          message={
            'A felhasználói fiók törlése végleges, ' +
            'és ezt követően ugyazzal az email címmel nem lehet újraregisztrálni.'
          }
          showModal={showConfirmModal}
          setShowModal={setShowConfirmModal}
          resultCallback={confirmCallback}
          okButton={'Megerősítem a fióktörlést'}
        />
        {errorMessage && (
          <div className="dashboard__inner--row">
            <span className="error">{errorMessage}</span>
          </div>
        )}
        {successMessage && (
          <div className="dashboard__inner--row">
            <span className="success">{successMessage}</span>
          </div>
        )}
        <button
          className="btn"
          onClick={() => setShowConfirmModal(true)}>Fiók törlése</button>
      </div>
    </>
  );
}

export default DeleteAccount;
