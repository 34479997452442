import { Modal, Button } from 'react-bootstrap';

const ConfirmModal = (props) => {
  const {
    title,
    message,
    showModal,
    setShowModal,
    resultCallback,
    okButton,
    cancelButton
  } = props;

  const click = (result) => {
    setShowModal(false);
    resultCallback(result);
  }

  return (
    <Modal show={showModal} centered>
      <Modal.Body>
        <h4>{title}</h4>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => click(true)}>
          {okButton ?? 'Rendben'}
        </Button>
        <Button variant="secondary" onClick={() => click(false)}>
          {cancelButton ?? 'Mégsem'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
