import React, { useState, useEffect, useContext } from 'react';

import swWord from '../../images/sw-word.svg';
import swCloud from '../../images/sw-cloud.svg';

import subscriptionService from '../../services/subscription';
import { secureFetch } from '../../libs/utils';

import { CartContext } from '../../reducers/cartReducer';
import SuccessModal from '../../components/modal/SuccessModal';
import CustomSigningOfferModal from '../../components/modal/CustomSigningOfferModal';

function Purchase() {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [ showCustomSigningOfferModal, setShowCustomSigningOfferModal ] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await secureFetch(
      () => subscriptionService.getProducts(),
      (response) => setProducts(response.products),
      setErrorMessage,
      'Nem sikerült lekérdezni a termékeket'
    );
  }

  const { dispatch } = useContext(CartContext);

  const addToCart = (id) => {
    const product = products.find((item) => item.id === id);
    dispatch({ type: 'add', payload: {
      productId: product.id,
      product: product,
      quantity: 1,
      netItemPrice: product.netPrice,
      vatPercentage: product.vatPercentage
    }});
    setShowModal(true);
  }

  return (
    <div className="dashboard__inner--row">
    <SuccessModal
      message="A termék bekerült a kosárba."
      timeout={2000}
      showModal={showModal}
      setShowModal={setShowModal}
    />
    {errorMessage && (
      <div className="row">
        <span className="error">{errorMessage}</span>
      </div>
    )}
    <h2>Vásárlás</h2>
      <h4 id="subscription">Előfizetés</h4>
      <div className="subscription">
        <div className="subscription__box">
          <div className="mb-3">
            <img src={swWord} alt="Signo Wise" />
          </div>
          <div>
            <h3>Egyéves előfizetés: 19 900 Ft + ÁFA<br/>
            Tartalmaz 20 db aláírókreditet</h3>
            <button className="btn" onClick={() => addToCart(1)}>
              Kosárba rakom
            </button>
          </div>
        </div>
        <div className="subscription__box">
          <div className="mb-3">
            <img src={swCloud} alt="Signo Wise" />
          </div>
          <div>
            <h3>Egyéves előfizetés: 299 900 Ft + ÁFA<br/>
            Tartalmaz 200 db aláírókreditet</h3>
            <button className="btn" onClick={() => addToCart(2)}>
              Kosárba rakom
            </button>
          </div>
        </div>
      </div>
      <h4 id="signatures">Aláírókreditek vásárlása</h4>
      <div className="enough__lines">
        <div className="enough__lines--line">
          <div className="lineborder">
            <div>
              <h4>Mikrovállalati csomag</h4>
              <h4>20 db aláírókredit</h4>
            </div>
            <div>
              <h3>9 900 Ft + ÁFA</h3>
              <button className="btn" onClick={() => addToCart(3)}>
                Kosárba rakom
              </button>
            </div>
          </div>
        </div>
        <div className="enough__lines--line">
          <div className="lineborder">
            <div>
              <h4>Kisvállalati csomag</h4>
              <h4>100 db aláírókredit</h4>
            </div>
            <div>
              <h3>34 900 Ft + ÁFA</h3>
              <button className="btn" onClick={() => addToCart(4)}>
                Kosárba rakom
              </button>
            </div>
          </div>
        </div>
        <div className="enough__lines--line">
          <div className="lineborder">
            <div>
              <h4>Középvállalati csomag</h4>
              <h4>1.000 db aláírókredit</h4>
            </div>
            <div>
              <h3>249 900 Ft + ÁFA</h3>
              <button className="btn" onClick={() => addToCart(5)}>
                Kosárba rakom
              </button>
            </div>
          </div>
        </div>
        <div className="enough__lines--line">
          <div className="lineborder">
            <div>
              <h4>Nagyvállalati csomag</h4>
              <h4>
                Évi 10 ezer - 100 millió
                dokumentum – jelentős kedvezménnyel
              </h4>
            </div>
            <div>
              <button
                className="btn"
                onClick={() => setShowCustomSigningOfferModal(true)}
              >
                Egyedi ajánlatot kérek
              </button>
              <CustomSigningOfferModal
                showModal={showCustomSigningOfferModal}
                setShowModal={setShowCustomSigningOfferModal}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Purchase;
