import React, { useState, useEffect, useCallback } from 'react';
import ReactTooltip from 'react-tooltip';

import { useFormFields } from '../../libs/hooks';
import userService from '../../services/user';
import subscriptionService from '../../services/subscription';
import { secureFetch } from '../../libs/utils';

function UpdateBillingAddress() {

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const [fields, updateField, setAll] = useFormFields({
    name: '',
    zipCode: '',
    city: '',
    address: '',
    emailAddress: '',
    vatNumber: '',
  });

  const handleFieldChange = (evt) => {
    updateField(evt);
    setErrorMessage(null);
    setSuccessMessage(null);
  }

  const fetchData = useCallback(async () => {
    await secureFetch(
      () => userService.getBillingAddress(),
      (response) => setAll(response.billingAddress),
      setErrorMessage,
      'Nem sikerült lekérdezni a számlázási címet'
    );
  }, [setAll]);

  const save = async (e) => {
    e.preventDefault();
    setErrorMessage(null);
    setSuccessMessage(null);

    if (!fields.name) {
      setErrorMessage('Kérjük, add meg a nevet, amire kiállíthatjuk a számlát!');
      return;
    }

    if (!fields.zipCode || !fields.city || !fields.address) {
      setErrorMessage('Kérjük, add meg a cím minden elemét!');
      return;
    }

    if (!fields.emailAddress) {
      setErrorMessage('Kérjük, add meg az e-mail címet, erre a címre küldjük ki a számlát!');
      return;
    }

    if (fields.vatNumber) {
      var result = await secureFetch(
        () => subscriptionService.validateBillingAddress(fields),
        () => { return true; },
        (msg) => {
          setErrorMessage(msg);
          return false;
        },
        'Érvénytelen adószám'
      );

      if(!result) {
        return;
      }
    }

    await secureFetch(
      () => userService.saveBillingAddress(fields),
      () => setSuccessMessage('Számlázási adatok módosítva'),
      setErrorMessage,
      'Hiba a számlázási adatok módosítása során'
    );
  };

  useEffect(() => {
    fetchData()
  }, [fetchData]);

  return (
    <>
      <div className="dashboard__inner--row">
        <h3>Számlázási adatok</h3>
        {errorMessage && (
          <div className="dashboard__inner--row">
            <span className="error">{errorMessage}</span>
          </div>
        )}
        {successMessage && (
          <div className="dashboard__inner--row">
            <span className="success">{successMessage}</span>
          </div>
        )}
        <input
          id="name"
          type="text"
          placeholder="Cégnév / Név"
          onChange={handleFieldChange}
          value={fields.name}
          data-tip
          data-for="name"
        />
        <ReactTooltip id="name">
          <span>
            Cégnév / Név
          </span>
        </ReactTooltip>
        <input
          id="zipCode"
          placeholder="Irányítószám"
          onChange={handleFieldChange}
          value={fields.zipCode}
          data-tip
          data-for="zip"
        />
        <ReactTooltip id="zip">
          <span>
            Irányítószám
          </span>
        </ReactTooltip>
        <input
          id="city"
          type="text"
          placeholder="Település"
          onChange={handleFieldChange}
          value={fields.city}
          data-tip
          data-for="city"
        />
        <ReactTooltip id="city">
          <span>
            Település
          </span>
        </ReactTooltip>
        <input
          id="address"
          type="text"
          placeholder="Cím"
          onChange={handleFieldChange}
          value={fields.address}
          data-tip
          data-for="address"
        />
        <ReactTooltip id="address">
          <span>
            Cím
          </span>
        </ReactTooltip>
        <input
          id="emailAddress"
          type="email"
          placeholder="Email cím"
          onChange={handleFieldChange}
          value={fields.emailAddress}
          data-tip
          data-for="emailAddress"
        />
        <ReactTooltip id="emailAddress">
          <span>
            Email cím
          </span>
        </ReactTooltip>
        <input
          id="vatNumber"
          placeholder="Adószám"
          onChange={handleFieldChange}
          value={fields.vatNumber}
          data-tip
          data-for="vatNumber"
        />
        <ReactTooltip id="vatNumber">
          <span>
            Adószám
          </span>
        </ReactTooltip>
        <button
          onClick={save}
          className="btn">
          Mentés
        </button>
      </div>
    </>
  );
}

export default UpdateBillingAddress;
