import Fade from 'react-reveal/Fade';
import Slider from 'react-slick';
import iconSettings from '../../images/icon-settings.svg';
import iconChart from '../../images/icon-chart.svg';
import iconSecurity from '../../images/icon-security.svg';
import advantageImg from '../../images/tablet-full.svg';

const Advantages = () => {

  const rollerSettings = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    speed: 1000,
    vertical: true,
    autoplay: true,
    cssEase: 'cubic-bezier(0.600, -0.280, 0.735, 0.045)',
  };

  return (
    <section className="advantages" id="advantages">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="title">
              <h3>
                <Fade left>
                  Egyedi előnyök
                </Fade>
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
            <div className="col-lg-7 spin-container">
              <div className="advantages__spinner">
                <div className="advantages__spinner--left">
                  <h2>1</h2>
                </div>
                <div className="advantages__spinner--right">
                  <Slider {...rollerSettings}>
                    <div>
                      <p>perc alatt megtanulható</p>
                    </div>
                    <div>
                      <p>kattintással indítható</p>
                    </div>
                    <div className="big">
                      <p>nap alatt bármilyen rendszerhez integrálható</p>
                    </div>
                  </Slider>
                </div>
              </div>
              <img src={advantageImg} alt="advantageImg" className="advantage-img" />
            </div>
            <div className="col-lg-5">
              <div className="row">
              <div className="col-lg-12">
                <div className="advantages__box">
                  <div className="advantages__box--title">
                    <img src={iconSettings} alt="settings" />
                    <h4>Rugalmas</h4>
                  </div>
                  <div className="advantages__box--content">
                    <ul>
                      <li>Indítsd közvetlenül Wordből vagy bármilyen dokumentumgeneráló rendszerből</li>
                      <li>Írj alá bármilyen tablettel</li>
                      <li>Futtasd public vagy private cloudban</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="advantages__box">
                  <div className="advantages__box--title">
                    <img src={iconChart} alt="chart" />
                    <h4>Hatékony</h4>
                  </div>
                  <div className="advantages__box--content">
                    <ul>
                      <li>Nem kell új alkalmazással, weboldallal vagy sablonokkal vesződnöd</li>
                      <li>Integráció és telepítés nélkül azonnal használható</li>
                      <li>Egynapos integrációt lehetővé tevő API (opcionális)</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="advantages__box">
                  <div className="advantages__box--title">
                    <img src={iconSecurity} alt="chart" />
                    <h4>Biztonságos</h4>
                  </div>
                  <div className="advantages__box--content">
                    <ul>
                      <li>Ptk. szerinti írásbeli szerződések készíthetők</li>
                      <li>Magyar jog, GDPR és eIDAS megfelelőség</li>
                      <li>Bankok által is használt, auditált rendszer</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Advantages;
