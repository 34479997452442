import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import arrowBtn from '../../images/arrow-btn.svg';
import CustomOfferModal from '../../components/modal/CustomOfferModal';
import CustomSigningOfferModal from '../../components/modal/CustomSigningOfferModal';
import authService from '../../services/auth';

const PurchaseButton = () =>
{
  const currentUser = authService.getCurrentUser();
  const history = useHistory();
  const link = currentUser ? '/dashboard/purchase' : 'register';
  return (
    <a href="#signatures" className="btn" onClick={() => history.push(link)}>
      Megvásárolom
      <img src={arrowBtn} alt="arrow-btn" />
    </a>
  );
}

const Enough = () => {
  const [ showCustomOfferModal, setShowCustomOfferModal ] = useState(false);
  const [ showCustomSigningOfferModal, setShowCustomSigningOfferModal ] = useState(false);

  return (
    <section className="enough">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="title">
              <h3>
                <Fade left>
                  Ha többet szeretnél aláírni
                </Fade>
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="enough__lines">
              <div className="enough__lines--line">
                <div className="lineborder">
                  <div>
                    <h4>20 db aláírókredit</h4>
                  </div>
                  <div>
                    <h3>9 900 Ft + ÁFA</h3>
                    <PurchaseButton/>
                  </div>
                </div>
              </div>
              <div className="enough__lines--line">
                <div className="lineborder">
                  <div>
                    <h4>100 db aláírókredit</h4>
                  </div>
                  <div>
                    <h3>34 900 Ft + ÁFA</h3>
                    <PurchaseButton/>
                  </div>
                </div>
              </div>
              <div className="enough__lines--line">
                <div className="lineborder">
                  <div>
                    <h4>1.000 db aláírókredit</h4>
                  </div>
                  <div>
                    <h3>249 900 Ft + ÁFA</h3>
                    <PurchaseButton/>
                  </div>
                </div>
              </div>
              <div className="enough__lines--line">
                <div className="lineborder">
                  <div>
                    <h4>
                      Évi 10 ezer - 100 millió
                      dokumentum – jelentős kedvezménnyel
                    </h4>
                  </div>
                  <div>
                    <button
                      className="btn"
                      onClick={() => setShowCustomSigningOfferModal(true)}
                    >
                      Egyedi ajánlatot kérek
                      <img src={arrowBtn} alt="arrow-btn" />
                    </button>
                    <CustomSigningOfferModal
                      showModal={showCustomSigningOfferModal}
                      setShowModal={setShowCustomSigningOfferModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p className="tip">
              Egy aláírókredittel egy dokumentumot lehet elektronikusan aláírni,
              az aláírók számától függetlenül. Érvényes aláírások használatához
              előfizetés szükséges. Az aláírókreditek tartalmazzák
              az időpecsétek és a minősített tanúsítvány díját.
            </p>
            <Link to="/register">
              <button className="btn">Regisztrálok <img src={arrowBtn} alt="arrow-btn" /></button>
            </Link>
          </div>
        </div>
        <div className="row more-option-container">
          <div className="col">
            <div className="title">
              <h3>
                <Fade left>
                  Egyedi opciók
                </Fade>
              </h3>
            </div>
            <div className="options">
              <div className="options__more">
                <div>
                  <h4>Céges branding (whitelabel)</h4>
                </div>
              </div>
              <div className="options__more">
                <div>
                  <h4>Private cloud, emelt szintű support</h4>
                </div>
              </div>
              <div className="options__more">
                <div>
                  <h4>Egyéni igények alapján specifikált bevezetés</h4>
                </div>
              </div>
              <div className="options__more">
                <div>
                  <h4>Bevezetés dokumentumkezelő rendszerrel együtt</h4>
                </div>
              </div>
            </div>
            <div className="bottom-info">
              <p>Webservice alapú dobozos megoldásunkat igény esetén személyre szabjuk.<br/>A SignoWise akár partnereink dokumentumkezelő rendszereivel együtt is elérhető.</p>
              <button
                className="btn"
                onClick={() => setShowCustomOfferModal(true)}
              >
                Egyedi ajánlatot kérek <img src={arrowBtn} alt="arrow-btn" />
              </button>
            </div>
            <CustomOfferModal
              showModal={showCustomOfferModal}
              setShowModal={setShowCustomOfferModal}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Enough;
