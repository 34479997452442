import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import swWord from '../../images/sw-word.svg';
import swCloud from '../../images/sw-cloud.svg';
import swDemo from '../../images/sw-word.svg';

import SuccessModal from '../../components/modal/SuccessModal';
import subscriptionService from '../../services/subscription';
import { getTimestamp, getDate, secureFetch } from '../../libs/utils'

function SubscriptionSummary() {
  const [userState, setUserState] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async() => {
    await secureFetch(
      () => subscriptionService.getUserStatus(),
      (response) => setUserState(response.status),
      setErrorMessage,
      'Nem sikerült lekérdezni az előfizetés státuszát'
    );
  }

  const now = getTimestamp();

  const getLogo = () => {
    if (userState.cloudExpiresAt > now) {
      return swCloud;
    }
    else if (userState.wordAddinExpiresAt > now) {
      return swWord;
    }
    return swDemo;
  }

  const getExpiryStatus = () => {
    if (userState.cloudExpiresAt > now) {
      return `Előfizetve ${getDate(userState.cloudExpiresAt, 'YYYY. MM. DD.')}-ig`;
    }
    else if (userState.wordAddinExpiresAt > now) {
      return `Előfizetve ${getDate(userState.wordAddinExpiresAt, 'YYYY. MM. DD.')}-ig`;
    }
    return 'Inaktív, csak próba';
  }

  const getRenewText = () => {
    if (
      userState.cloudExpiresAt > now ||
      userState.wordAddinExpiresAt > now ) {
      return 'Meghosszabbítom az előfizetést';
    }
    return 'Előfizetek';
  }

  const requestTrialSignatures = async (e) => {
    e.preventDefault();

    await secureFetch(
      () => subscriptionService.requestTrialSignatures(),
      () => {
        setShowModal(true);
        fetchData();
      },
      setErrorMessage,
      'Nem sikerült próba aláírást kérni'
    );
  };

  return (
    <div className="dashboard__inner--row subscription">
      {userState != null && (
        <>
          {errorMessage && (
            <div className="row">
              <span className="error">{errorMessage}</span>
            </div>
          )}
          <SuccessModal
            message="A próba aláírásokat jóváírtuk."
            timeout={2000}
            showModal={showModal}
            setShowModal={setShowModal}
          />
          <img src={getLogo()} alt="subscriptionLogo"/>
          <div className="row">
            <div className="col-6 d-flex">
              {getExpiryStatus()}
            </div>
            <div className="col-6 d-flex">
              <a href="#subscription" className="btn" onClick={() => history.push('/dashboard/purchase')}>
                {getRenewText()}
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex">
              Rendelkezésre álló aláírókreditek: {userState.availableSignatureCount}
            </div>
            <div className="col d-flex">
              <a href="#signatures" className="btn" onClick={() => history.push('/dashboard/purchase')}>
                Veszek még aláírókreditet
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col d-flex">
              Rendelkezésre álló tesztaláírások: {userState.availableDemoSignatureCount}
            </div>
            <div className="col d-flex">
              <button className="btn" onClick={requestTrialSignatures}>
                Kérek még tesztaláírást
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SubscriptionSummary;
