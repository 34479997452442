import moment from 'moment';

export function getDate(timestamp, format) {
  const date = new Date(timestamp);
  if (format) {
    return moment(date).format(format);
  } else {
    return moment(date).format('YYYY. MM. DD. HH:mm:ss');
  }
}

export function getMeta(document, key) {
  const meta = document.metaData.find(meta => meta.key === key);
  if(meta) {
    return meta.value;
  }
  return null;
}

export function getTimestamp() {
  return new Date().getTime();
}

export async function secureFetch (
  fetchResult,
  processResult,
  handleError,
  defaultErrorMessage) {
    try {
      handleError('');
      const result = await fetchResult();
      if (result.errorCode === 0) {
        return processResult(result);
      } else {
        handleError(result.errorMessage ?? defaultErrorMessage);
      }
    } catch {
      handleError(defaultErrorMessage);
    }
}

export function openPopup(callback) {
  const newTabInstance = window.open('', '_blank');
  callback.then(url => {
    if(url) {
      newTabInstance.location.href = url;
    } else {
      newTabInstance.close();
    }
  });
}

export function round(number, precision) {
  const multiplier = 10 ^ precision;
  return Math.round((number + Number.EPSILON) * multiplier) / multiplier;
}

export function renderPrice(num) {
  return num
    .toFixed(0)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') + ' Ft';
}
