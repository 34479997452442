import React, { useState, useEffect } from 'react';

import subscriptionService from '../../services/subscription';
import authService from '../../services/auth';
import { downloadFile } from '../../libs/downloadHelper'
import { secureFetch } from '../../libs/utils'

import moment from 'moment';
import 'moment/locale/hu';

function SubscriptionSummary() {
  const now = new Date();

  const [usageStat, setUsageStat] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [month, setMonth] = useState(moment(now).format('YYYYMM'));

  const fields = [{
      key: 'prodDocuments',
      label: 'Érvényesen aláírt dokumentumok'
    }, {
      key: 'demoDocuments',
      label: 'Tesztaláírással aláírt dokumentumok'
    }];

  moment.locale('hu');
  const user = authService.getCurrentUser();

  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async () => {
    await secureFetch(
      () => subscriptionService.getUsageData(),
      (response) => setUsageStat(response.usageData),
      setErrorMessage,
      'Nem sikerült lekérdezni az adatokat'
    );
  }

  const downloadStat = async () => {
    await secureFetch(
      () => subscriptionService.getDetailedUsageData(month),
      (response) => downloadFile(`${month}.csv`, response.file),
      setErrorMessage,
      'Nem sikerült letölteni a részletes statisztikát'
    );
  }

  const getMonths = () => {
    let options = [];
    let date = moment(now);
    if (user) {
      while(date >= new Date(user.createdAt)) {
        options.push((<option value={date.format('YYYYMM')}>{date.format('YYYY. MMMM')}</option>));
        date.add(-1, 'months');
      }
    }
    return options;
  }

  return (
    <>
      <div className="dashboard__inner--row">
        <h3>Használati statisztika</h3>
      </div>
      <div className="dashboard__inner--row">
        {errorMessage && (
          <div className="row">
            <span className="error">{errorMessage}</span>
          </div>
        )}
        {usageStat && (
          <table className="table">
            <thead>
              <tr>
                <th></th>
                <th>{moment(now).format('YYYY. MMMM')}</th>
                <th>{moment(now).format('YYYY.')}</th>
                <th>Összesen</th>
              </tr>
            </thead>
            <tbody>
              {
                fields.map(field =>(
                  <tr>
                    <td>{field.label}</td>
                    <td>{usageStat[0][field.key]}</td>
                    <td>{usageStat[1][field.key]}</td>
                    <td>{usageStat[2][field.key]}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        )}
      </div>
      <div className="dashboard__inner--row">
        <h4>Részletes statisztika letöltése</h4>
      </div>

      <div className="dashboard__inner--row">
        <div className="row">
          <div className="col d-flex">
            <select name="month" onChange={(evt) => setMonth(evt.target.value)}>
              {getMonths()}
            </select>
          </div>
          <div className="col d-flex">
            <div className="btn" onClick={downloadStat}><i className="fa fa-download" aria-hidden="true"></i> Letöltöm</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubscriptionSummary;
