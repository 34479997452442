import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import customOfferService from '../../services/customOffer';
import authService from '../../services/auth';
import { secureFetch } from '../../libs/utils';
import { useFormFields } from '../../libs/hooks';


const CustomOfferModal = (props) => {
  const { showModal, setShowModal} = props;

  const [ errorMessage, setErrorMessage ] = useState(null);

  const currentUser = authService.getCurrentUser();
  const defaultFormValue = {
    companyName: '',
    companySector: '',
    email: currentUser ? currentUser.email : '',
    phoneNumber: '',
    plannedUsage: '',
    extraOptions: ''
  };
  const [ fields, handleFieldChange, setAll ] = useFormFields(defaultFormValue);

  const hasEmptyField = () => !fields.companyName ||
    !fields.companySector ||
    !fields.email ||
    !fields.phoneNumber ||
    !fields.plannedUsage ||
    !fields.extraOptions
  ;

  const save = async () => {
    setErrorMessage(null);

    await secureFetch(
      () => customOfferService.customOptions(fields),
      () => {
        setShowModal(false);
        setAll(defaultFormValue);
      },
      setErrorMessage,
      'Hiba az ajánlatkérés elküldése során'
    );
  };

  return (
    <Modal
      show={showModal}
      centered
      onHide={() => setShowModal(false)}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Egyedi ajánlat kérése
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <div className="form__row">
            <span className="error">{errorMessage}</span>
          </div>
        )}
        <div className="form__row">
          <input
            id="companyName"
            placeholder="Cég neve"
            onChange={handleFieldChange}
            value={fields.companyName}
            required
          />
        </div>
        <div className="form__row">
          <input
            id="companySector"
            placeholder="Cég iparága pl. egészségügy"
            value={fields.companySector}
            onChange={handleFieldChange}
            required
          />
        </div>
        <div className="form__row">
          <input
            id="email"
            placeholder="Email cím"
            value={fields.email}
            onChange={handleFieldChange}
            requei
          />
        </div>
        <div className="form__row">
          <input
            id="phoneNumber"
            placeholder="Telefonszám"
            value={fields.phoneNumber}
            onChange={handleFieldChange}
            required
          />
        </div>
        <div className="form__row">
          <textarea
            id="plannedUsage"
            placeholder="Mire szeretnéd használni a SignoWise rendszert (minél pontosabban leírva)?
            Pl. egyedi papír alapú nyomtatványaim szeretném elektronikusan aláírni.
            A dokumentumokat egy web formon kitöltöm, majd alá szeretném íratni egy tableten a páciensemmel."
            value={fields.plannedUsage}
            onChange={handleFieldChange}
            rows={4}
            required
          />
        </div>
        <div className="form__row">
          <textarea
            id="extraOptions"
            placeholder="Mire kérsz egyedi ajánlatot (minél pontosabban leírva)?
            Pl. nem tudok aláírómezőt elhelyezni. A használt web alkalmazás …, ehhez kellene aláíró mezőt illeszteni, erre kérnék egyeztetést és ajánlatot."
            value={fields.extraOptions}
            onChange={handleFieldChange}
            rows={4}
            required
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={hasEmptyField()}
          onClick={() => save()}
        >
          Küldés
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CustomOfferModal;
