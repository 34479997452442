import React, { useState } from 'react';

import '../App.scss';
import Hero from '../sections/hero/Hero';
import Advantages from '../sections/advantages/Advantages';
import About from '../sections/about/About';
import Guarantee from '../sections/guarantee/Guarantee';
import Prices from '../sections/prices/Prices';
import Enough from '../sections/enough/Enough';
import Faq from '../sections/faq/Faq';

function Landing() {

  const [activeTab, setActiveTab] = useState('how-to');
  const [activeAccordion, setActiveAccordion] = useState('');

  return (
    <>
      <Hero />
      <Advantages />
      <About />
      <Guarantee
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setActiveAccordion={setActiveAccordion}
      />
      <Prices />
      <Enough />
      <Faq
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        activeAccordion={activeAccordion}
        setActiveAccordion={setActiveAccordion}
      />
    </>
  );
}

export default Landing;
